import React, { useEffect, useState } from "react";
import "./HomeSubscribe.css";
import subscribeBtn from "./subscribeBtn.svg";

import client from "../../../sanityCli";
import { v4 as uuidv4 } from "uuid";
import imageUrlBuilder from "@sanity/image-url";
const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function HomeSubscribe() {
  const [subscribers, setSubscribers] = useState(null);

  const emailInput = document.getElementById("subscribersEmail");
  // console.log("emailInput", emailInput);
  async function subscribe(email) {
    // console.log(email);
    await postData(email).then(() => (emailInput.value = ""));
  }
 
  useEffect(() => {
    const fetch =   async () => {
     fetchSubscribers();
    };
    fetch();

  }, [subscribers]);

  const fetchSubscribers = async () => {
    try {
      const data = await client.fetch('*[_type == "subscribers"][0]');
      setSubscribers(data);
      // console.log("subscribers", data);
      return data; // Return data to potentially use its ID
    } catch (error) {
      console.error("Error fetching data from Sanity:", error);
    }
  };
  const postData = async (subscriber) => {
    await fetchSubscribers().then((data) => {
      // console.log("data fetched", data);
      if (data) {
        const newSubmissionData = {
          email: subscriber,
          _key: uuidv4(),
        };
        appendSubmission(data._id, newSubmissionData);
      }
    });
  };

  const appendSubmission = async (documentId, newSubmissionData) => {
    // newSubmissionData._key = uuidv4();
    try {
      await client
        .patch(documentId) // Use the document ID
        .setIfMissing({ subscribersList: [] }) // Initializes submissions array if it doesn't exist
        .insert("after", "subscribersList[-1]", [newSubmissionData]) // Appends the new submission
        .commit();

      console.log("Form submission updated and published successfully.");
    } catch (error) {
      console.error("Error updating and publishing form submission:", error);
    }
  };

  return (
    <div className="subscribe-container relative py-10 md:w-4/5 w-4/5 lg:h-96 mx-auto lg:my-16 mb-16 lg:py-20 font-display flex flex-col items-center justify-center lg:gap-10 gap-4 rounded-tl-3xl rounded-tr rounded-bl-lg rounded-br-2xl">
      {/* {subscribers && (
        <img
          src={urlFor(subscribers.image_1)}
          alt="bg"
          className="lg:w-1/3 w-1/2 absolute lg:-left-20 -left-8 bottom-0"
        />
      )} */}
      {/* {subscribers && (
        <img
          src={urlFor(subscribers.image_2)}
          alt="bg"
          className="w-8 lg:w-10 absolute lg:-right-2 -right-4 top-0 lg:-mt-4 -mt-6"
        />
      )} */}
      {/* {subscribers && (
        <img
          src={urlFor(subscribers.image_3)}
          alt="bg"
          className="lg:w-1/5 w-1/3 absolute right-2 top-0"
        />
      )} */}
      {subscribers && (
        <h1 className="lg:text-4xl text-[8px] text-center font-semibold lg:w-4/5 px-2 text-gray-600">
          {subscribers.title}
        </h1>
      )}
      <div className="flex items-center md:w-4/6 justify-center gap-1 mx-1 md:gap-8 md:px-4">
        <div class="flex items-center md:gap-4 gap-2 border border-gray-300 bg-white md:py-4 md:px-8 px-2 py-2 md:rounded-2xl rounded-md w-full">
          <label for="email">
            {subscribers && (
              <img
                src={urlFor(subscribers.image_4)}
                alt="Email Icon"
                className="w-6"
              />
            )}
          </label>
          <input
            type="email"
            id="subscribersEmail"
            name="email"
            placeholder="Enter your email"
            className="md:px-2 md:py-2 w-full bg-white rounded-lg focus:outline-none focus:ring-2 focus:ring-white md:text-xl text-base font-normal"
          />
        </div>
        <img
          src={subscribeBtn}
          alt="subscribe-button"
          className="md:w-40 w-20"
          onClick={() => subscribe(emailInput.value)}
        />
     
      </div>
    </div>
  );
}
