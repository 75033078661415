
import React, { useState, useEffect } from "react";
// import client from "../../../sanityCli";

export default function HomeFaq({ faqData }) {
  const [faqItems, setFaqItems] = useState([]);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");

  useEffect(() => {
    if (faqData) {
      const { title, subtitle, faqItems } = faqData;
      setTitle(title || "");
      setSubtitle(subtitle || "");
      setFaqItems(faqItems || []);
    }
  }, [faqData]);

  const [expandedItems, setExpandedItems] = useState({});

  const toggleExpansion = (index) => {
    setExpandedItems((prevExpandedItems) => ({
      ...prevExpandedItems,
      [index]: !prevExpandedItems[index],
    }));
  };

  return (
    <div className="w-full py-20 flex flex-col items-center justify-center space-y-8">
      <div className="flex flex-col items-center justify-center md:space-y-8 space-y-4">
        <h1 className="text-2xl  md:text-3xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-bold leading-tight text-black text-center">
         {title}
        </h1>
        <p className="text-[12px] md:text-xl lg:text-2xl text-center font-normal leading-relaxed text-gray-700">
         {subtitle}
        </p>
      </div>
      <div className="w-full px-6 md:px-16">
        {faqItems.map((item, index) => (
          <div
            key={index}
            className={`w-full border-t border-gray-300 ${
              index === faqItems.length - 1 ? "border-b" : ""
            }`}
          >
            <div className="flex items-center justify-between gap-4 py-4 md:py-4">
              <p className="text-[12px] md:text-2xl md:font-normal font-normal leading-tight text-black">
                {item.question}
              </p>
              <span
                className={`bg-yellow-50 text-black flex items-center justify-center lg:w-16 lg:h-16 w-8 h-6 rounded-full cursor-pointer ${
                  expandedItems[index] ? "bg-yellow-200 text-gray-600" : ""
                }`}
                onClick={() => toggleExpansion(index)}
              >
                {expandedItems[index] ? <>&times;</> : <>&#43;</>}
              </span>
            </div>
            {expandedItems[index] && (
              <p className="pb-4 mt-2 md:mt-0 md:text-base text-[10px] font-normal leading-relaxed text-gray-700">
                {item.answer}
              </p>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}









// import React, { useState, useEffect } from "react";
// import client from "../../../sanityCli";

// export default function HomeFaq() {
//   const [faqItems, setFaqItems] = useState([]);
//   const [title, setTitle] = useState("");
//   const [subtitle, setSubtitle] = useState("");

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         // Fetch FAQ items
//         const faqResult = await client.fetch('*[_type == "faq"]');
//         const { title, subtitle, faqItems } = faqResult[0] || {};
//         setTitle(title || "");
//         setSubtitle(subtitle || "");
//         setFaqItems(faqItems || []);
//       } catch (error) {
//         console.error("Error fetching data from Sanity:", error);
//       }
//     };
  
//     fetchData();
//   }, []);

//   const [expandedItems, setExpandedItems] = useState({});

//   const toggleExpansion = (index) => {
//     setExpandedItems((prevExpandedItems) => ({
//       ...prevExpandedItems,
//       [index]: !prevExpandedItems[index],
//     }));
//   };

//   return (
//     <div className="w-full py-20 flex flex-col items-center justify-center space-y-8">
//       <div className="flex flex-col items-center justify-center md:space-y-8 space-y-4">
//         <h1 className="text-3xl md:text-6xl font-semibold leading-tight text-black text-center">
//          {title}
//         </h1>
//         <p className="text-lg md:text-3xl text-center font-normal leading-relaxed text-gray-700">
//          {subtitle}
//         </p>
//       </div>
//       <div className="w-full px-6 md:px-16">
//         {faqItems.map((item, index) => (
//           <div
//             key={index}
//             className={`w-full border-t border-gray-300 ${
//               index === faqItems.length - 1 ? "border-b" : ""
//             }`}
//           >
//             <div className="flex items-center justify-between gap-4 py-4 md:py-4">
//               <p className="text-sm md:text-3xl md:font-normal font-normal leading-tight text-black">
//                 {item.question}
//               </p>
//               <span
//                 className={`bg-yellow-100 text-black flex items-center justify-center lg:w-16 lg:h-16 w-8 h-6 rounded-full cursor-pointer ${
//                   expandedItems[index] ? "bg-yellow-300 text-black" : ""
//                 }`}
//                 onClick={() => toggleExpansion(index)}
//               >
//                 {expandedItems[index] ? <>&times;</> : <>&#43;</>}
//               </span>
//             </div>
//             {expandedItems[index] && (
//               <p className="pb-4 mt-2 md:mt-0 md:text-xl text-sm font-normal leading-relaxed text-gray-700">
//                 {item.answer}
//               </p>
//             )}
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }
