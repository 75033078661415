import React, { useState } from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../Home/homeSec9/Footer";

function ReviewComment() {
  const [comment, setComment] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);


  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    if (inputValue.trim()) { // Check if input is not empty
        setComment([...comment, { text: inputValue, completed: false }]);
      setInputValue(""); // Clear input field after adding a todo
    }
  };

  const handleTodoDelete = (index) => {
    const newComment = [...comment]; // Create a copy of the todos array
    newComment.splice(index, 1); // Remove the todo at the specified index
    setComment(newComment);
  };

  const handleTodoToggle = (index) => {
    const newComment = [...comment]; // Create a copy of the todos array
    newComment[index].completed = !newComment[index].completed; // Toggle the completed state
    setComment(newComment);
  };

  return (
    <>
    <Navbar />
    <div className="todo-list flex-col items-start space-y-8 w-1/2 justify-center">
      <h1>Review Comment</h1>
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder="Enter a todo"
            className=""
          />
          {/* Allow media and GIF access (implementation depends on your chosen library) */}
          <input type="file" onChange={handleFileChange} />
          <button type="button">Media</button>
          <button type="button">GIF</button>
        </div>
        <button type="submit">Add Comment</button>
      </form>
      <ul>
        {comment.map((comment, index) => (
          <li key={index}>
            <input
              type="checkbox"
              checked={comment.completed}
              onChange={() => handleTodoToggle(index)}
            />
            <span
              style={{ textDecoration: comment.completed ? "line-through" : "none" }}
            >
              {comment.text}
            </span>
            <button onClick={() => handleTodoDelete(index)}>Delete</button>
          </li>
        ))}
      </ul>
    </div>
    <Footer />
    </>
  );
}

export default ReviewComment;
