import React, { useState, useEffect } from "react";
import client from "../../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source);
}

function OurServices() {
  const [servicesData, setServicesData] = useState(null);
  const [showDetails, setShowDetails] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    client
      .fetch('*[_type == "services"][0]')
      .then((data) => {
        setServicesData(data);
        setShowDetails(Array(data.expandText.length).fill(false));
      })
      .catch(console.error);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isHovered && servicesData) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % servicesData.crouselCards.length);
      }
    }, 4000);

    return () => clearInterval(interval);
  }, [isHovered, servicesData]);

  if (!servicesData) {
    return <div>Loading...</div>; // or any loading indicator
  }

  const { title, subTitle, description, expandText, crouselCards } =
    servicesData;

  const toggleDetailVisibility = (index) => {
    const newShowDetails = [...showDetails];
    newShowDetails[index] = !newShowDetails[index];
    setShowDetails(newShowDetails);
  };

  return (
    <div className="our-services lg:py-8 py-4" id="whatWeDo">
      <div className="our-services-heading flex flex-col items-center justify-center lg:space-y-8 md:space-y-6 space-y-3 lg:py-16 py-10 px-10 lg:px-0 pb-0  text-center">
        <p className="text-[12px] lg:text-[14px] xl:text-[16px] 2xl:text-xl  text-yellow-600 font-semibold uppercase leading-3 xl:leading-4">
          {title}
        </p>
        <h2 className="text-[4vw] md:text-4xl xl:text-5xl 2xl:text-[56px] leading-6 md:leading-6 lg:leading-8 xl:leading-10 2xl:leading-[64px] text-gray-700 tracking-wider font-bold uppercase">
          {subTitle}
        </h2>
        <p className="text-[3.8vw] md:text-2xl xl:[3xl] hidden md:block w-[50vw] text-gray-600 text-center font-normal">
          {description}
        </p>
        <p className="text-sm md:text-2xl block md:hidden  text-gray-600 text-center font-normal">
          {description}
        </p>
      </div>
      <div className="our-services-cards w-full lg:px-16 lg:py-10 px-[5vw] py-4  flex flex-col-reverse lg:flex-row items-center">
        {/* Left Cards */}
        <div className="left-cards lg:w-[50vw] w-full flex-col items-center space-y-6">
          {expandText.map((text, index) => (
            <div
              key={index}
              className="left-card p-[4vw] lg:p-[3vw] rounded-[1vw] relative"
              style={{ background: "#383840" }}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-6 ">
                  <p className="lg:text-[2.5vw] md:text-xl text-[5vw] font-semibold text-slate-100">
                    {text.expandTextTitle}
                  </p>
                  {text.expandTextTitleIcon && (
                    <img
                      className="w-[6vw] lg:w-[2.5vw]"
                      src={urlFor(text.expandTextTitleIcon)}
                      alt={text.expandTextTitleIcon.alt}
                    />
                  )}
                </div>
                <div
                  className="expand-btn bg-yellow-500 rounded-full w-[10vw] h-[10vw] lg:w-[6vw] lg:h-[6vw]  flex items-center justify-center cursor-pointer"
                  onClick={() => toggleDetailVisibility(index)}
                  style={{
                    transform: showDetails[index]
                      ? "rotate(90deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                >
                  {text.expandIcon && (
                    <img
                      className="w-[2vw]"
                      src={urlFor(text.expandIcon)}
                      alt={text.expandIcon.alt}
                    />
                  )}
                </div>
              </div>
              {showDetails[index] && (
                <div className="left-card-detail-text text-slate-200 lg:pr-[8vw] lg:text-2.8vw xl:text-xl 2xl:text-2xl text-[3vw] font-normal mt-4">
                  {text.expandTextDescription}
                </div>
              )}
            </div>
          ))}
        </div>
        {/* Carousel */}
        <div
          className="right-card-crousel bg-yellow-400 rounded-lg lg:p-4 my-8 lg:m-10 overflow-hidden w-full lg:w-1/2"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="crousel-container top-0 left-0 w-full h-full flex">
            {crouselCards.map((image, index) => (
              <div
                key={index}
                className={`crousel-card-image w-full h-full transition-transform duration-500 ${
                  index === currentIndex ? "" : "hidden"
                }`}
              >
                {image.crouselCardImage && (
                  <img
                    className="rounded-3xl p-6 lg:w-[100%]"
                    src={urlFor(image.crouselCardImage)}
                    alt={`crousel-card-${index}`}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurServices;


// import React, { useState, useEffect } from "react";

// function OurServices() {
//   const cardData = [
//     {headerTitle: " our services"},
//     {headerSubTitle: "What we can do for you"},
//     {headerDescription:" From ideation to launch, our tailored services are designed to ensure your gaming project's success at every stage."},

//     {
//       title: "Game Publishing",
//       icon: "🎮",
//       detail:
//         "From ideation to launch, our tailored services are designed to ensure your gaming project's success at every stage.",
//     },
//     {
//       title: "Game Development",
//       icon: "🕹️",
//       detail:
//         "Our expert team will bring your game ideas to life with cutting-edge development techniques and innovative design.",
//     },
//     {
//       title: "Game Marketing",
//       icon: "📣",
//       detail:
//         "Maximize your game's visibility and reach your target audience with our strategic marketing campaigns and promotion services.",
//     },
//   ];

//   const [showDetails, setShowDetails] = useState(
//     Array(cardData.length).fill(false)
//   );

//   const [currentIndex, setCurrentIndex] = useState(0);
//   const [isHovered, setIsHovered] = useState(false);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (!isHovered) {
//         const nextIndex = (currentIndex + 1) % crouselCards.length;
//         setCurrentIndex(nextIndex);
//       }
//     }, 2000);
//     return () => clearInterval(interval);
//   }, [currentIndex, isHovered]);

//   const toggleDetailVisibility = (index) => {
//     const newShowDetails = [...showDetails];
//     newShowDetails[index] = !newShowDetails[index];
//     setShowDetails(newShowDetails);
//   };

//   return (
//     <div className="our-services lg:py-8 py-4" id="whatWeDo">
//       <div className="our-services-heading flex flex-col items-center justify-center lg:space-y-4 space-y-2 lg:py-16 py-4 px-10 lg:px-0  text-center">
//         <p className="lg:text-xl text-sm text-yellow-600 font-semibold uppercase">
//            {cardData.headerTitle}
//         </p>
//         <h2 className="sm:text-sm md:text-xl xl:text-5xl text-gray-800 font-bold uppercase">
//            {cardData.headerSubTitle}
//         </h2>
//         <p className="text-sm md:text-2xl hidden md:block  text-gray-600 font-sm text-center font-normal">
//         {cardData.headerSubTitle}
//         </p>
//         <p className="text-sm md:text-2xl block md:hidden  text-gray-600 font-sm text-center font-normal">
//           {cardData.headerDescription}
//         </p>
//       </div>
//       <div className="our-services-cards w-full lg:px-16 lg:py-10 px-10 py-4  flex flex-col-reverse lg:flex-row items-center">
//         <div className="left-cards lg:w-1/2 w-full flex-col items-center space-y-6">
//           {cardData.map((card, index) => (
//             <div
//               key={index}
//               className="left-card lg:py-8 lg:px-10 p-7 rounded-2xl relative mr-4"
//               style={{ background: "#383840" }}
//             >
//               <div className="flex items-center justify-between">
//                 <div className="flex items-center space-x-6 text-white">
//                   <p className="lg:text-3xl md:text-xl text-xl font-semibold">
//                     {card.title}
//                   </p>
//                   <img className="xl:text-5xl md:3xl text-2xl" src={card.icon} />
//                 </div>
//                 <div
//                   className="expand-btn bg-yellow-500 rounded-full lg:w-12 lg:h-12 w-8 h-8 flex items-center justify-center cursor-pointer"
//                   onClick={() => toggleDetailVisibility(index)}
//                   style={{
//                     transform: showDetails[index]
//                       ? "rotate(90deg)"
//                       : "rotate(0deg)",
//                     transition: "transform 0.3s ease",
//                   }}
//                 >
//                   <span className="lg:text-3xl text-xl ml-4">〉</span>
//                 </div>
//               </div>
//               {showDetails[index] && (
//                 <div className="left-card-detail-text text-white lg:text-2xl text-lg font-normal mt-4">
//                   {card.detail}
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//         <div
//           className="right-card-crousel bg-yellow-400 rounded-lg lg:p-4 my-8 lg:m-10  overflow-hidden w-full lg:w-1/2"

//           onMouseEnter={() => setIsHovered(true)}
//           onMouseLeave={() => setIsHovered(false)}
//         >
//           <div className="crousel-container top-0 left-0 w-full h-full flex">
//             {crouselCards.map((image, index) => (
//               <div
//                 key={index}
//                 className={`crousel-card-image w-full h-full transition-transform duration-500 ${
//                   index === currentIndex ? "" : "hidden"
//                 }`}
//               >
//                 <img
//                   className="2xl:rounded-[80px] xl:rounded-[40px] rounded-[20px] lg:w-[100%] lg:p-[5%] lg:h-full w-[100%] p-[10%]"
//                   src={image}
//                   alt={`crousel-card-${index}`}
//                 />
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default OurServices;
