import React, { useState, useEffect } from "react";
import client from "../../../sanityCli"; // Import your Sanity client
import imageUrlBuilder from "@sanity/image-url";
import { Link } from "react-router-dom";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function TopGames() {
  const [topGameData, setTopGameData] = useState(null);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const [showSingleCard, setShowSingleCard] = useState(false);
  const [filteredGamesCards, setFilteredGamesCards] = useState([]);

  useEffect(() => {
    // Fetch data from Sanity
    client
      .fetch('*[_type == "topGame"][0]')
      .then((data) => setTopGameData(data))

      .catch(console.error);
  }, []);

  console.log("view now", topGameData);

  useEffect(() => {
    // Filter the gamesCards array based on the selected type
    if (filterCriteria && topGameData) {
      const filteredCards = topGameData.gamesCards.filter(
        (game) => game.type === filterCriteria
      );
      setFilteredGamesCards(filteredCards);
    } else if (topGameData) {
      setFilteredGamesCards(topGameData.gamesCards);
    }
  }, [filterCriteria, topGameData]);

  const filterGamesByType = (type) => {
    setFilterCriteria(type);
    setStartIndex(0);
  };

  const handleToggleNext = () => {
    const nextIndex =
      startIndex + 1 >= filteredGamesCards.length ? 0 : startIndex + 1;
    setStartIndex(nextIndex);
  };

  const handleToggleBack = () => {
    const prevIndex =
      startIndex === 0 ? filteredGamesCards.length - 1 : startIndex - 1;
    setStartIndex(prevIndex);
  };

  const isActive = (type) => {
    return type === filterCriteria;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleToggleNext();
    }, 3000); // Automatic slide every 3 seconds

    return () => clearInterval(interval);
  }, [startIndex, filterCriteria]);

  useEffect(() => {
    const handleResize = () => {
      setShowSingleCard(window.innerWidth < 768);
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (!topGameData) {
    return <div>Loading...</div>;
  }

  const { heading, spanText, ourGames, upcomingGames, gamesCards } =
    topGameData;

  return (
    <div
      className="top-games-container flex flex-col lg:gap-8 gap-2"
      id="topGames"
    >
      <h1 className="xl:text-5xl 2xl:text-7xl text-[6vw] md:text-[5vw] mt-[1vh] lg:mt-12 font-semibold px-[20vw] xl:px-[34vw] 2xl:px-[28vw] text-center text-gray-900">
        {heading} <span className="text-yellow-600">{spanText}</span>
      </h1>
      <div className="filtered-list flex items-center justify-center space-x-5 my-8">
        <button
          className={`p-4 uppercase relative font-medium ${
            isActive(ourGames)
              ? "text-[3.2vw] lg:text-2xl xl:text-3xl 2xl:text-[32px] font-semibold"
              : "text-[3vw] lg:text-xl xl:text-2xl 2xl:text-[28px] font-semibold"
          }`}
          onClick={() => filterGamesByType(ourGames)}
        >
          <span className="relative z-10">{ourGames}</span>
          <span
            className={`absolute top-0 left-0 right-0 bottom-0 ${
              isActive(ourGames)
                ? "text-gray-900 bg-gradient-to-r from-yellow-500 to-yellow-300 transform skew-x-[-20deg] z-0"
                : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform skew-x-[-20deg] z-0"
            }`}
          ></span>
        </button>
        <button
          className={`p-4 uppercase relative text-[3vw] xl:text-[32px] font-medium ${
            isActive(upcomingGames)
              ? "text-[3.2vw] lg:text-2xl xl:text-3xl 2xl:text-[32px] font-semibold"
              : "text-[3vw] lg:text-xl xl:text-2xl 2xl:text-[28px] font-semibold"
          }`}
          onClick={() => filterGamesByType(upcomingGames)}
        >
          <span className="relative z-10">{upcomingGames}</span>
          <span
            className={`absolute top-0 left-0 right-0 bottom-0 ${
              isActive(upcomingGames)
                ? "text-gray-900 bg-gradient-to-r from-yellow-500 to-yellow-300 transform skew-x-[-20deg] z-0"
                : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform skew-x-[-20deg] z-0"
            }`}
          ></span>
        </button>
      </div>

      <div className="top-games-card-container relative overflow-auto lg:mt-4 mb-8 z-20">
        <button
          className="toggle-back-btn absolute md:left-5 left-[35vw] z-10 md:top-1/2 top-[110%]  text-white bg-black rounded-full px-4 py-3   transform -translate-y-1/2"
          onClick={handleToggleBack}
        >
          ←
        </button>
        <button
          className="toggle-nxt-btn absolute md:right-5 right-[35vw] md:top-1/2 top-[110%]  z-10 text-white bg-black rounded-full px-4 py-3  transform -translate-y-1/2"
          onClick={handleToggleNext}
        >
          →
        </button>
        <div className="w-full flex items-center justify-center">
          {filteredGamesCards.map((game, index) => (
            <div
              key={index}
              className={`top-games-card lg:w-[28%] z-50 w-full rounded-lg xl:p-8 lg:p-6 p-[4vw]  mx-2 ${
                (index >= startIndex &&
                  index < startIndex + 3 &&
                  !showSingleCard) ||
                (index === startIndex && showSingleCard)
                  ? "block"
                  : "hidden"
              } bg-gradient-to-r from-yellow-500 to-yellow-300 border-solid border-[1px] border-black rounded-lg shadow-lg backdrop-blur-lg mx-[2vw] md:mx-2`}
            >
              <img
                src={urlFor(game.cardImage)
                  .width(500)
                  .height(400)
                  .fit("crop")
                  .url()}
                className="w-full h-40 lg:h-[20vh] rounded-xl"
                alt={game.name}
              />
              <p className="py-2 font-semibold text-2xl">{game.name}</p>
              <p className="py-1">{game.description}</p>
              <div className="game-info w-full my-1 flex flex-wrap">
                {game.developers.map((developer, index) => (
                  <div
                    key={index}
                    className="developer w-1/2 flex items-start space-x-2 my-3"
                  >
                    <div className="developer-icon bg-black rounded-full w-10 h-10 flex items-center justify-center">
                      <img
                        src={urlFor(developer.icon)
                          .width(50)
                          .height(50)
                          .fit("crop")
                          .url()}
                        className="w-6 h-6 rounded-full"
                      />
                    </div>
                    <div className="developer-name-role">
                      <strong>{developer.title}</strong>
                      <p>{developer.subTitle}</p>
                    </div>
                  </div>
                ))}

                <button className="relative text-white text-center font-semibold bg-gray-800 w-full p-2 overflow-hidden skew-x-[-20deg] uppercase">
                  <Link
                    to={game.viewNowLink}
                    target="blanck"
                    className="relative z-10"
                  >
                    {game.viewNow}
                  </Link>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TopGames;

// import React, { useState, useEffect } from "react";
// import { gamesData } from "../../utils/TopGames/mockData";

// function TopGames() {
//   const [filterCriteria, setFilterCriteria] = useState(null);
//   const [startIndex, setStartIndex] = useState(0);

//   const filterGamesByTitle = (title) => {
//     setFilterCriteria(title);
//     setStartIndex(0);
//   };

//   const filteredGameData = filterCriteria
//     ? gamesData.filter((game) => game.title === filterCriteria)
//     : gamesData;

//   const handleToggleNext = () => {
//     const nextIndex =
//       startIndex + 1 >= filteredGameData.length ? 0 : startIndex + 1;
//     setStartIndex(nextIndex);
//   };

//   const handleToggleBack = () => {
//     const prevIndex =
//       startIndex === 0 ? filteredGameData.length - 1 : startIndex - 1;
//     setStartIndex(prevIndex);
//   };
//   const isActive = (title) => {
//     return title === filterCriteria;
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       handleToggleNext();
//     }, 3000); // Automatic slide every 3 seconds

//     return () => clearInterval(interval);
//   }, [startIndex, filteredGameData.length]);

//   const [showSingleCard, setShowSingleCard] = useState(false);

//   useEffect(() => {
//     const handleResize = () => {
//       setShowSingleCard(window.innerWidth < 768);
//     };

//     handleResize(); // Initial check
//     window.addEventListener("resize", handleResize);

//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   return (
//     <div className="top-games-container flex flex-col gap-8" id="topGames">
//       <h1 className="xl:text-5xl md:3xl text-2xl mt-12 font-semibold text-center text-gray-900">
//        {topGames.heading}
//       </h1>
//       <div className="filtered-list flex items-center justify-center space-x-5 my-8">
//         <button
//           className={`p-4 uppercase relative text-base font-medium  ${
//             isActive("OUR PARTNER") ? "text-[20px] font-semibold" : ""
//           }`}
//           onClick={() => filterGamesByTitle("OUR PARTNER")}
//         >
//           <span className="relative z-10">{topGames.gameCard.type}</span> here I want Our Partner
//           <span
//             className={`absolute top-0 left-0 right-0 bottom-0 ${
//               isActive("OUR PARTNER")
//                 ? "text-gray-900 bg-gradient-to-r from-yellow-500 to-yellow-300 transform skew-x-[-20deg] z-0"
//                 : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform skew-x-[-20deg] z-0"
//             }`}
//           ></span>
//         </button>
//         <button
//           className={`p-4 uppercase relative text-base font-medium ${
//             isActive("UPCOMING GAMES") ? "text-[20px] font-semibold" : ""
//           }`}
//           onClick={() => filterGamesByTitle("UPCOMING GAMES")}
//         >
//           <span className="relative z-10">{topGames.gameCard.type}</span> here I want Upcoming Games
//           <span
//             className={`absolute top-0 left-0 right-0 bottom-0 ${
//               isActive("UPCOMING GAMES")
//                 ? "text-gray-900 bg-gradient-to-r from-yellow-500 to-yellow-300 transform skew-x-[-20deg] z-0"
//                 : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform skew-x-[-20deg] z-0"
//             }`}
//           ></span>
//         </button>
//       </div>

//       <div className="top-games-card-container relative overflow-auto lg:mt-4 mb-8 z-20">
//         <button
//           className="toggle-back-btn absolute md:left-5 left-40 z-10 md:top-1/2 top-[110%]  text-white bg-black rounded-full px-4 py-3   transform -translate-y-1/2"
//           onClick={handleToggleBack}
//         >
//           ←
//         </button>
//         <button
//           className="toggle-nxt-btn absolute md:right-5 right-40 md:top-1/2 top-[110%]  z-10 text-white bg-black rounded-full px-4 py-3  transform -translate-y-1/2"
//           onClick={handleToggleNext}
//         >
//           →
//         </button>
//         <div className="w-full flex items-center justify-center">
//           {filteredGameData.map((game, index) => (
//             <div
//               key={game.id}
//               className={`top-games-card lg:w-[28%] rounded-lg p-8 mx-2 ${
//                 (index >= startIndex &&
//                   index < startIndex + 3 &&
//                   !showSingleCard) ||
//                 (index === startIndex && showSingleCard)
//                   ? "block"
//                   : "hidden"
//               } bg-gradient-to-r from-yellow-500 to-yellow-300 border-solid border-[1px] border-black rounded-lg shadow-lg backdrop-blur-lg mx-6 md:mx-2`}
//             >
//               <img src={game.image} className="w-full h-60" alt={game.name} />
//               <p className="py-2 font-semibold text-2xl">{game.name}</p>
//               <p className="py-2">{game.description}</p>
//               <div className="game-info w-full my-1 flex flex-wrap">
//                 {game.developers.map((developer) => (
//                   <div
//                     key={developer.id}
//                     className="developer w-1/2 flex items-start space-x-2 my-4"
//                   >
//                     <div className="developer-icon bg-black rounded-full w-10 h-10 flex items-center justify-center">
//                       <img
//                         src={developer.icon}
//                         className="w-6 h-6 rounded-full"
//                       />
//                     </div>
//                     <div className="developer-name-role">
//                       <strong>{developer.position}</strong>
//                       <p>{developer.name}</p>
//                     </div>
//                   </div>
//                 ))}

//                 <button className="relative text-white text-center font-semibold bg-gray-800 w-full p-2 overflow-hidden skew-x-[-20deg]">
//                   <span className="relative z-10">VIEW NOW</span>
//                 </button>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default TopGames;
