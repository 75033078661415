import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import HomeSubscribe from "../Home/homeSec8/HomeSubscribe";
import client from "../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";
import Navbar from "../navbar/Navbar";
import Footer from "../Home/homeSec9/Footer";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function BlogDetail() {
  const [blogData, setBlogData] = useState(null); // State to hold fetched blog data
  const [pageData, setPageData] = useState(null); 
  const { id } = useParams();
 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.fetch(`
          *[_type == 'blogs']{
            ...
          }
        `);
        setBlogData(data); // Update blogData with fetched data
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };
    const fetchPageData = async () => {
      try {
        const result = await client.fetch('*[_type == "pageContent"][0]');
        setPageData(result);
        console.log("pageNavData", result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };
    fetchPageData();


    fetchData(); // Fetch blog data on component mount
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);

  // Render loading state if blogData is null
  if (!blogData) return <div>Loading...</div>;

  // Find the blog with the matching path
  const blog = blogData[0].blogData.find((blog) => blog.path === id);

  // Render if blog is not found
  if (!blog) return <div>Blog not found</div>;

  // Sort the blogData array based on views (descending order)
  const mostViewedBlogs = blogData[0].blogData.slice(1).sort((a, b) => b.views - a.views);

  // Limit to top 5 most viewed blogs
  const top5MostViewedBlogs = mostViewedBlogs.slice(0, 5);

  return (
    <>
    <Navbar />
    <div className="blog-detail-container pl-0 lg:pl-[5%]">
      {/* Render the blog details */}
      <section className="px-[5%] py-10 lg:py-20 flex flex-col items-center justify-center space-y-8">
        <p className="uppercase text-sm md:text-base font-semibold text-gray-500">{blog.type} Type</p>
        <h1 className="uppercase text-2xl lg:text-3xl xl:text-5xl 2xl:text-[55px] text-gray-900 text-center font-semibold">
          {blog.blogHeader}
        </h1>
        <p className="uppercase text-sm md:text-base font-semibold text-gray-500">
          {blog.blogReleaseDate}{" "} <strong>.</strong> {blog.blogReleaseTime}
        </p>
        <div className="flex items-center gap-2">
          <img
            src={urlFor(blog.blogImage)}
            alt={blog.authorName}
            className="w-20 h-20 rounded-full"
          />
          <div className="flex flex-col items-start gap-2">
            <p className="capitlize text-2xl text-gra8-500">
              {blog.blogAuthor}
            </p>
            <p className="uppercase text-sm text-gray-500">{blog.type} Type</p>
          </div>
        </div>
      </section>
      <div className="w-full lg:p-0 px-5">
        <div className="w-full">
          <img
            src={urlFor(blog.blogDetailContent.blogDetailImage)}
            alt={blog.blogTypes}
            className="w-full lg:pr-20"
          />
        </div>
        <div className="blog-detail-text my-12 flex items-start gap-12">
          <div className="w-full lg:w-2/3">
            <p className="text-xl font-normal text-gray-700 mb-8">
              {blog.blogDetailContent.paragraph}
            </p>
            {blog.blogDetailTextContent.map((blogDetailText, id) => (
              <div key={id} className="">
                <h1 className="text-2xl text-gray-900 my-8">
                  {blogDetailText.header}
                </h1>
                <p>{blogDetailText.description}</p>
                {blogDetailText.blogDetailImage && ( // Check if blogDetailImage exists
                  <div className="w-full">
                    <img
                      src={blogDetailText.blogDetailImage}
                      alt="Blog Detail"
                      className="w-full mb-12"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="hidden lg:block w-1/3 pr-20">
            <h1 className="text-3xl text-gray-800 uppercase mb-6">
              most Viewed blog
            </h1>
            <ul className="w-full flex flex-col items-start gap-12">
              {pageData && top5MostViewedBlogs.map((blog, index) => (
                <li key={blog.path}>
                  <Link
                    to={`/${pageData.blog ? pageData.blog : 'blog'}/${blog.path}`}
                    className={
                      index === 0
                        ? "flex flex-col-reverse gap-8 border border-1"
                        : "flex items-center border-b"
                    }
                  >
                    <div className=" w-4/5 flex items-start space-x-12 p-4">
                      <div>
                        <strong className={"w-1/6 text-2xl text-black font-semibold"}>
                         {index + 1}
                        </strong>
                      </div>
                      <div className="flex flex-col items-start w-5/6 hover:text-xl hover:font-semibold">
                        <p className="text-amber-400 text-2xl">{blog.mostViewedStories[0].title}</p>
                        <p className="mt-2 text-lg hover:text-lg hover:font-semibold">{blog.mostViewedStories[0].title}</p>
                      </div>
                    </div>
                    <div>
                      <img
                        src={urlFor(blog.mostViewedStories[0].image)}
                        alt={blog.type}
                        className={index === 0 ? "w-full h-48 rounded-xl" : " w-32 h-20 rounded-xl"}
                      />
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <HomeSubscribe />
    </div>
    <Footer />
    </>
  );
}

export default BlogDetail;


// / import React, { useState, useEffect } from "react";
// import { useParams, useLocation, Link } from "react-router-dom";
// import client from "../../sanityCli";
// import HomeSubscribe from "../Home/homeSec8/HomeSubscribe";
// import linkedinIcon from "../assets/linkedin.svg";
// import imageUrlBuilder from "@sanity/image-url";

// const builder = imageUrlBuilder(client);

// function urlFor(source) {
//   return builder.image(source);
// }

// const BlogDetail = () => {
//   const { id } = useParams();
//   const [blog, setBlog] = useState(null);
//   const [allBlogs, setAllBlogs] = useState([]);
//   const location = useLocation();

//   useEffect(() => {
//     let passedValue = localStorage.getItem("myData");
//     if (passedValue !== "true") {
//       window.location.reload();
//       localStorage.setItem("myData", "true");
//     }

//     if (location.state) {
//       setBlog(location.state);
//     }
//     fetchData();
//   }, [location.state]);

//   useEffect(() => {
//     window.scrollTo(0, 0); // Scroll to the top of the page when component mounts or updates
//   }, [id]); // Trigger effect when id changes

//    for (let index = 2; index >= 0; index++) {
//     const element = [];
//     element.push(index);
//    }
//   const fetchData = async () => {
//     try {
//       const data = await client.fetch('*[_type == "blogs"]');
//       setAllBlogs(data[0].blogs);
//     } catch (error) {
//       console.error("Error fetching data from Sanity:", error);
//     }
//   };

//   return (
//     <>
//       <div className="w-full flex items-start gap-8 lg:px-24">
//         <div className="lg:w-[95%] flex flex-col items-center gap-8 py-10">
//           {blog && (
//             <>
//               <h1>{blog.type}</h1>
//               <p>{blog.description}</p>
//               <p>
//                 {blog.releaseDate} . {blog.releaseTime}
//               </p>
//               <div className="flex items-center gap-2">
//                 {blog.additionalData && (
//                   <img
//                     src={urlFor(blog.additionalData.authorImage)}
//                     alt="/"
//                     className="w-20 p-2 rounded-full font-semibold"
//                   />
//                 )}
//                 <div className="">
//                   <p className="text-xl text-black-500 font-semibold">
//                     {blog.author}
//                   </p>
//                   <p className="text-l text-black-300 font-normal">
//                     {blog.type}
//                   </p>
//                 </div>
//               </div>
//               {blog.additionalData && (
//                 <div className="w-full">
//                   <img
//                     src={urlFor(blog.additionalData.image)}
//                     alt={`blog/img/${id}`}
//                     className="w-full"
//                   />
//                   <div className="flex items-start space-x-12">
//                     <div className="lg:w-[75%] w-full my-8 px-2 lg:px-0 flex flex-col items-start gap-6">
//                       <p className="text-normal text-gray-800 font-normal">
//                         {blog.additionalData.text}
//                       </p>
//                       <div>
//                         {blog.additionalData.titles.map((title, index) => (
//                           <div key={index}>
//                             <p className="text-3xl font-medium text-gray-800 py-6 mt-10">
//                               {title}
//                             </p>
//                             <p className="text-base font-medium text-gray-900">
//                               {blog.additionalData.descriptions[index]}
//                             </p>
//                           </div>
//                         ))}
//                       </div>
//                       <div className=" w-full flex flex-col items-start gap-4 mt-12">
//                         <h1 className="text-3xl font-medium text-gray-800">
//                           {blog.additionalData.detailTitle}
//                         </h1>
//                         {blog.additionalData.detailTitleImage && (
//                           <img
//                             src={urlFor(blog.additionalData.detailTitleImage)}
//                             alt={`blog/img/${id}`}
//                             className="w-full h-[540px] my-6"
//                           />
//                         )}
//                         <h1 className="text-gray-700 text-lg font-medium">
//                           {blog.additionalData.detailParagraph}
//                         </h1>
//                       </div>
//                     </div>
//                     <div className="w-[25%] mt-8">
//                       <h2 className="text-black font-montserrat font-semibold text-xl leading-6 uppercase">Most Viewed Blogs</h2>
//                       <ul>
//                         {allBlogs.slice(0, 5).map((blog, index) => (
//                           <li key={blog.id} className="my-8">
//                            <Link to={`/blog/${blog.additionalData.path}`} state={blog}>
//                               <img
//                                 src={urlFor(blog.additionalData.image)}
//                                 alt={`blog/img/${blog.id}`}
//                                 className="w-full"
//                               />
//                               <span>
//                                 <strong>{index + 1}</strong>{" "}
//                                 {blog.type} - {blog.description.substring(0, 30)}...
//                               </span>
//                             </Link>
//                           </li>
//                         ))}
//                       </ul>
//                     </div>
//                   </div>
//                 </div>
//               )}
//             </>
//           )}
//         </div>
//         <div className="lg:w-[5%] mt-40 hidden lg:block">
//           <img src={linkedinIcon} alt="/" />
//           <img src={linkedinIcon} alt="/" />
//           <img src={linkedinIcon} alt="/" />

//         </div>
//       </div>
//       <HomeSubscribe />
//     </>
//   );
// };

// export default BlogDetail;
