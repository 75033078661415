import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { reviewsData } from "../utils/ReviewData/mockData";
import HomeSubscribe from "../Home/homeSec8/HomeSubscribe";
import client from "../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";
import Navbar from "../navbar/Navbar";
import Footer from "../Home/homeSec9/Footer";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function RatingDetailPage() {
  const location = useLocation();
  console.log("location", location);
  const { id } = useParams();
  const review = location.state;

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page when component mounts or updates
  }, [id]); // Trigger effect when id changes

  if (!review) {
    return <div>Review not found</div>;
  }

  const reviewRating = review.reviewRating;

  return (
    <>
      <Navbar />
      <div className="review-rating-detail-page">
        <div className="py-12 lg:px-60 xl:px-96 flex-col space-y-8">
          <p className="text-center font-montserrat text-5xl 2xl:text-[56px] leading-[65px] font-bold tracking-wider uppercase text-gray-800">
            {reviewRating.reviewName}
          </p>
          <p className="text-xl 2xl:text-2xl leading-8 font-normal text-gray-700 font-poppins text-center">
            {reviewRating.subTitle}
          </p>
          <img
            src={urlFor(reviewRating.reviewImage)}
            alt={reviewRating.reviewName}
            className="w-full h-[400px]"
          />
        </div>
        <div className="py-20 px-20 2xl:px-28 flex-col items-center space-y-8 ">
          <p className="text-gray-800 text-6xl font-semibold">
            {reviewRating.reviewDetailPageHeading.slice(0, 40)}
          </p>
          <p className=" text-xl 2xl:text-2xl leading-10 font-normal text-gray-700 font-poppins border-b border-gray-300 pb-16">
            {reviewRating.reviewDetailPageDescription}
          </p>
        </div>
        <div className="rating-container my-20 px-20 2xl:px-28">
          {reviewRating.reviewRatingDetail.map((review, id) => (
            <div key={id} className="my-20 flex pb-10 border-b-2">
              <div className="w-full relative">
                <div className="flex items-center gap-4 w-1/2">
                  <div className="flex items-center justify-center w-20 h-20 2xl:w-32 2xl:h-32 p-[1vw] 2xl:p-6 bg-black text-white font-bold text-3xl rounded-full">
                    {review.reviewRating}
                  </div>
                  <p className="text-4xl font-semiold text-gray-900">
                    {review.reviewRatingTitle}
                  </p>
                </div>
                <p className="my-[2%] w-[95%] text-base lg:text-xl 2xl:text-2xl leading-10 font-normal text-gray-700 font-poppins">
                  {review.reviewRatingDescription}
                </p>
              </div>

              <img
                src={urlFor(review.reviewRatingImage)}
                alt={review.reviewRatingTitle}
                className="w-1/2 h-96 rounded-lg"
              />
            </div>
          ))}
        </div>
        <HomeSubscribe />
      </div>
      <Footer />
    </>
  );
}

export default RatingDetailPage;
