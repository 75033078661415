import React, { useState, useEffect } from "react";
import {Link} from 'react-router-dom';
import client from "../../../sanityCli";
import "./Footer.css";
import imageUrlBuilder from "@sanity/image-url";

// Function to build image URLs
const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source);
}

export default function Footer() {
  const [footerData, setFooterData] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.fetch('*[_type == "footer"][0]');
        setFooterData(data);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    const fetchPageData = async () => {
      try {
        const result = await client.fetch('*[_type == "pageContent"][0]');
        setPageData(result);
        console.log("pageNavData", result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };
    fetchPageData();

    fetchData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!footerData) return null;

  const { companySection, legalSection, contactSection, socialMediaSection } =
    footerData;

  return (
    <>
      {/* Desktop Footer */}
      <div className="footer-container bg-black hidden md:block">
        <div className="footer-link">
          <div className="footer-sec-1">
            {companySection.logo && (
              <img src={urlFor(companySection.logo)} alt="/" />
            )}
            <p>{companySection.description}</p>
            <p>{companySection.copyright}</p>
            {companySection.indicLogo && (
              <img src={urlFor(companySection.indicLogo)} alt="/" />
            )}
          </div>
          <div className="footer-sec-2">
            <h1>Company</h1>
            {footerData && pageData && <Link to={`/${pageData.privacyPolicy ? pageData.privacyPoliicy:'privacyPolicy' }`} >{footerData.companySection.company1}</Link>}
            {footerData && pageData && <Link to={`/${pageData.reviews ? pageData.reviews :'reviews' }`} >{footerData.companySection.company2}</Link>}
         
          </div>
          <div className="footer-sec-3">
            <h1>Legals</h1>
            {footerData && pageData && <Link to={`/${pageData.privacyPolicy ? pageData.privacyPolicy:'privacyPolicy' }`} >{footerData.legalSection.termsCondition}</Link>}
            {footerData && pageData && <Link to={`/${pageData.privacyPolicy ? pageData.privacyPolicy:'privacyPolicy' }`} >{footerData.legalSection.privacyPolicy}</Link>}
            {footerData && pageData && <Link to={`/${pageData.cookiesPolicy ? pageData.cookiesPolicy:'cookiesPolicy' }`} >{footerData.legalSection.cookiesPolicy}</Link>}
        
          </div>
          <div className="footer-sec-4">
            <h1>Contact</h1>
            {contactSection.items.map((item, index) => (
              <span key={index}>
                {item.icon && <img src={urlFor(item.icon)} alt="icon" />}
                <p>
                  <a href={item.url}>{item.text}</a>
                </p>
              </span>
            ))}
          </div>
          <div className="footer-sec-5">
            <h1>Social Media</h1>
            <div>
              {socialMediaSection.items.map((item, index) => (
                <a key={index} href={item.url}>
                  {item.icon && <img src={urlFor(item.icon)} alt="/" />}
                </a>
              ))}
            </div>
          </div>
        </div>
        <span className="footer-line"></span>
        <p className="footer-text">{companySection.copyright}</p>
      </div>

      {/* Mobile Footer */}
      <div className="parent block md:hidden w-full bg-black py-10 px-4">
        <div className="flex flex-col w-full gap-2">
          {companySection.logo && (
            <img src={urlFor(companySection.logo)} alt="/" className="w-40" />
          )}
          <p className="text-gray-300 text-lg font-normal">
            {companySection.description}
          </p>
          <p className="text-gray-200 text-lg">{companySection.copyright}</p>
        </div>
        <div className="flex items-start justify-between my-8">
          <div className="flex flex-col items-start gap-3 text-white">
            <h1 className="text-white text-xl font-medium">Company</h1>
            {footerData && pageData && <Link to={`/${pageData.privacyPolicy ? pageData.privacyPolicy:'privacyPolicy' }`} >{footerData.companySection.company1}</Link>}
            {footerData && pageData && <Link to={`/${pageData.reviews ? pageData.reviews :'reviews' }`} >{footerData.companySection.company2}</Link>}
          </div>
          <div className="flex flex-col items-start gap-3 text-white">
            <h1 className="text-white text-xl font-medium">Legals</h1>
            {footerData && pageData && <Link to={`/${pageData.privacyPolicy ? pageData.privacyPolicy:'privacyPolicy' }`} >{footerData.legalSection.termsCondition}</Link>}
            {footerData && pageData && <Link to={`/${pageData.privacyPolicy ? pageData.privacyPolicy:'privacyPolicy' }`} >{footerData.legalSection.privacyPolicy}</Link>}
            {footerData && pageData && <Link to={`/${pageData.cookiesPolicy ? pageData.cookiesPolicy:'cookiesPolicy' }`} >{footerData.legalSection.cookiesPolicy}</Link>}
          </div>
        </div>
        <div className="flex flex-col items-start gap-3">
          <h1 className="text-white text-xl font-medium">Contact</h1>
         {contactSection.items.map((item, index) => (
            <span key={index} className="flex items-center gap-2">
              {item.icon && <img src={urlFor(item.icon)} alt="icon" />}
              <p className="text-base text-center text-white">
                <a href={item.url}>{item.text}</a>
              </p>
            </span>
          ))} 
        </div>
        {companySection.indicLogo && (
          <img src={urlFor(companySection.indicLogo)} alt="/" className="w-48 my-4"/>
        )}
        <div className="flex items-start flex-col">
          <h1 className="text-white text-xl font-medium">Social Media</h1>
          <div className="flex items-start gap-0 mt-2">
            {socialMediaSection.items.map((item, index) => (
              <a key={index} href={item.url}>
                {item.icon && <img src={urlFor(item.icon)} alt="/" />}
              </a>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

// import React from "react";
// import "./Footer.css";
// import logoImg from "./footerLogo.svg";
// import indicLogo from "./indic.svg";
// import mail from "./mail.svg";
// import phone from "./Vector.svg";
// import fbIcon from "./Group 85.svg";
// import xIcon from "./Group 86.svg";
// import linkedinIcon from "./Group 87.svg";
// import instaIcon from "./Group 88.svg";

// export default function Footer() {
//   return (
//     <>
//       {/* desktop footer */}
//       <div className="footer-container md:block hidden bg-black">
//         <div className="footer-link">
//           <div className="footer-sec-1">
//             <img src={logoImg} alt="/" />
//             <p>
//               Revolutionizing Play, Transforming
//               <br /> Worlds.
//             </p>
//             <p>@copyright</p>
//             <img src={indicLogo} alt="/" />
//           </div>
//           <div className="footer-sec-2">
//             <h1>Compqany</h1>
//             <p>Market Research</p>
//             <p>Game Design</p>
//             <p>Economy Design</p>
//             <p>Tokenomy Design</p>
//           </div>
//           <div className="footer-sec-3">
//             <h1>Legals</h1>
//             <p>Terms & Conditions</p>
//             <p>Privacy Policy</p>
//             <p>Cookie Policy</p>
//           </div>
//           <div className="footer-sec-4">
//             <h1>Contact</h1>
//             <span>
//               <img src={mail} alt="mail-icon" />
//               <p>business@indicarena.com</p>
//             </span>
//             <span>
//               <img src={phone} alt="mail-icon" />
//               <p>+91 98765 43210</p>
//             </span>
//             <span>
//               <img src={mail} alt="mail-icon" />
//               <p>2972 Westheimer Rd. Santa Ana, Illinois 85486 </p>
//             </span>
//           </div>
//           <div className="footer-sec-5">
//             <h1>Social Media</h1>
//             <div>
//               <img src={fbIcon} alt="/" />
//               <img src={linkedinIcon} alt="/" />
//               <img src={xIcon} alt="/" />
//               <img src={instaIcon} alt="/" />
//             </div>
//           </div>
//         </div>
//         <span className="footer-line"></span>
//         <p className="footer-text">Copyright ® 2023 All Rights Reserved</p>
//       </div>

//       {/* mobile footer */}
//       <div className="parent md:hidden block w-full bg-black py-10 px-4">
//         <div className="flex flex-col w-ful gap-2">
//           <img src={logoImg} alt="/" className="w-40" />
//           <p className="text-gray-300 text-lg font-normal">
//             Revolutionizing Play, Transforming
//             <br /> Worlds.
//           </p>
//           <p className="text-gray-200 text-lg">@copyright</p>
//         </div>
//         <div className="flex items-start justify-between my-8">
//           <div className="flex flex-col items-start gap-3 text-white">
//             <h1 className="text-white text-xl font-medium">Compqany</h1>
//             <p className="text-gray-300 text-sm font-normal"> Market Research</p>
//             <p className="text-gray-300 text-sm font-normal">Game Design</p>
//             <p className="text-gray-300 text-sm font-normal">Economy Design</p>
//             <p className="text-gray-300 text-sm font-normal">Tokenomy Design</p>
//           </div>
//           <div className="flex flex-col items-start gap-3 text-white">
//             <h1 className="text-white text-xl font-medium">Legals</h1>
//             <p className="text-gray-300 text-sm font-normal">Terms & Conditions</p>
//             <p className="text-gray-300 text-sm font-normal">Privacy Policy</p>
//             <p className="text-gray-300 text-sm font-normal">Cookie Policy</p>
//           </div>
//         </div>
//         <div className=" flex flex-col items-start gap-3">
//           <h1 className="text-white text-xl font-medium">Contact</h1>
//           <span className="flex items-center gap-2">
//             <img src={mail} alt="mail-icon" />
//             <p className="text-gray-300 text-sm font-normal">business@indicarena.com</p>
//           </span>
//           <span className="flex items-center gap-2">
//             <img src={phone} alt="mail-icon" />
//             <p className="text-gray-300 text-sm font-normal">+91 98765 43210</p>
//           </span>
//           <span className="flex items-center gap-2">
//             <img src={mail} alt="mail-icon" />
//             <p className="text-gray-300 text-sm font-normal">2972 Westheimer Rd. Santa Ana, Illinois 85486 </p>
//           </span>
//         </div>
//         <img src={indicLogo} alt="/" className="mt-4 w-48"/>
//         <div className="flex items-start flex-col">
//             <h1 className="text-white text-xl font-medium">Social Media</h1>
//             <div className="flex items-start gap-0 mt-2">
//               <img src={fbIcon} alt="/" className="w-8" />
//               <img src={linkedinIcon} alt="/" className="w-8" />
//               <img src={xIcon} alt="/" className="w-8"/>
//               <img src={instaIcon} alt="/" className="w-8" />
//             </div>
//           </div>
//       </div>
//     </>
//   );
// }
