import sanityClient from '@sanity/client';


const client = sanityClient({
  projectId: 'r5y5zbpd', // Replace with your project ID
  dataset: 'production',     // Replace with your dataset name
  token: process.env.REACT_APP_SANITY_TOKEN,
});

export default client;

