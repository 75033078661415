import React, { useState, useEffect } from "react";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../../sanityCli";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function HomeOurTeam() {
  const [teamMembers, setTeamMembers] = useState([]);
  const [ navdata, setNavData] = useState(null)
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [bgColors] = useState([
    "bg-pink-900",
    "bg-green-400",
    "bg-purple-600",

  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await client.fetch('*[_type == "teamMember"]');
        const { title, subTitle, members } = result[0] || {};
        setTitle(title || "");
        setSubTitle(subTitle || "");
        setTeamMembers(members || []);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    const fetchNavData = async () => {
      try {
        const result = await client.fetch('*[_type == "navbarContent"][0]');
        setNavData(result);
        console.log('navdata', result)
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchNavData();

    fetchData();
  }, []);

  return (
    <div className="flex flex-col gap-6 lg:gap-12 items-center py-8 " id='team'>
      <div className="flex flex-col items-center space-y-4">
        {/* <p className="text-yellow-500 text-2xl font-semibold my-8">{title}</p> */}
        <p className="text-yellow-500 text-sm md:text-base lg:text-2xl font-semibold my-4 hover:underline transform hover:scale-105 hover:cursor-alias transition-transform">
          {title}
        </p>

        <h4 className="text-xl lg:text-4xl font-bold text-slate-900 tracking-wider">{subTitle}</h4>
      </div>

      <div className="py-4 flex justify-center items-center flex-wrap gap-4 w-full lg:w-4/5 lg:h-full">
        {teamMembers.map((member, index) => (
          <div
            key={index}
            className={`team-member relative lg:w-[32%] w-2/5 lg:h-[420px] xl:h-[480px] 2xl:h-[640px] h-[240px] flex flex-col items-center gap-4 xl:gap-12 py-4 px-1 rounded-xl text-white ${bgColors[index]}`}
          >
            <p className="text-[14px] md:text-base lg:text-2xl font-semibold text-slate-200">
              {member.name}
            </p>
            {member.image && (
              <div className="lg:w-[100%] lg:h-80 w-28 h-28 overflow-hidden rounded-full">
                <img
                  src={urlFor(member.image)}
                  alt={member.name}
                  className="w-full h-full object-cover"
                />
              </div>
            )}
            <h3 className="text-[12px] md:text-sm lg:text-xl font-normal text-center">
              {member.role}
            </h3>
          </div>
        ))}
      </div>
    </div>
  );
}
