import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";
import "./HomeHero.css";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function HomeHero(f_heroData) {
  const [heroData, setHeroData] = useState(f_heroData);

  useEffect(() => {
    if (f_heroData !== heroData) {
      setHeroData(f_heroData);
    }
  }, [f_heroData]);
  const {
    backgroundImage,
    heroText,
    description,
    buttonText,
    rightBackgroundImage,
    rightImage,
    socialMediaIcons,
  } = heroData?.heroData;

  return (
    <div
      className="home-hero md:bg-cover md:bg-center lg:pt-10 pt-4 pb-8 lg:pb-0 md:pt-16 md:pb-0 pl-4 md:pl-10 lg:pl-20 2xl:pl-28 flex flex-col-reverse items-center justify-center md:flex-row md:items-center "
      style={{ backgroundImage: `url(${urlFor(backgroundImage)})` }}
    >
      <div className="home-hero-left flex items-start flex-col gap-6 w-full lg:w-3/5 ">
        <h1
          className="md:text-2xl lg:text-3xl xl:text-5xl 2xl:text-[63px] leading-[7vw] xl:leading-[72px] 2xl-leading-[95px] font-bold text-gray-800 hidden md:block"
        >
          {heroText}
        </h1>

        <p
          className="text-[4vw] pr-[3vw] md:text-2xl 2xl:text-[32px] leading-[5vw] xl:leading-8 2xl:leading-10 text-gray-500"
        >
          {description}
        </p>
        <div className="flex bg-gradient-to-r from-yellow-500 to-yellow-300 rounded-xl px-4 py-3 hover:scale-105 transition-all">
          <p className="text-[16px] 2xl:text-[22px] leading-[30px] font-semibold"><Link to = "https://indiclancers.com" target="blanck">Get in touch </Link><span className="mb-8">↗️</span></p> 
        </div>
      </div>
      <div
        className="home-hero-right w-full lg:w-2/5 relative lg:bg-right lg:bg-cover bg-cover bg-left-bottom  bg-no-repeat pb-6"
        style={{ backgroundImage: `url(${urlFor(rightBackgroundImage)})` }}
      >
        <h1 className="text-[5vw] pr-6 md:text-5x block md:hidden font-bold text-gray-800">
          {heroText}
        </h1>
        {rightImage && (
          <img
            src={urlFor(rightImage)}
            alt="rightImage"
            className="remote-img mt-4 md:mt-10 md:w-4/5 mx-auto"
          />
        )}
        <div className="social-media-icon md:flex flex-col md:absolute bottom-10 right-5">
          {socialMediaIcons.map(({ link, image }, index) => (
            <Link key={index} to={link} target="_blank">
              {image && (
                <img
                  src={urlFor(image)}
                  alt={`social-icon-${index}`}
                  className="w-9 h-9 mb-2 hidden md:block"
                />
              )}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}
