import React, { useState, useEffect } from "react";
import client from "../../../sanityCli";
import "./HomeHero.css";
import imageUrlBuilder from "@sanity/image-url";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function HomeHeroBottom() {
  const [bottomItems, setBottomItems] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await client.fetch('*[_type == "homeHeroBottom"]');
        setBottomItems(result[0]?.bottomItems || []); // Access the 'bottomItems' array from the fetched result

        // Calculate the total item count and set CSS variables
          // Set CSS variable for item count
          document.documentElement.style.setProperty("--item-count", result[0]?.bottomItems.length || 0);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  return (
    // <div className="home-hero flex items-center justify-start md:justify-between overflow-hidden bg-gradient-to-l from-yellow-400 to-yellow-500">
    //   {bottomItems.map((item, index) => (
    //     <div
    //       key={index}
    //       className="block_1 flex items-center gap-2 animate-slide-left py-4"
    //     >
    //       {item.image && (
    //         <img
    //           src={urlFor(item.image)}
    //           alt={`bottom-item-${index}`}
    //           className="w-6 md:w-8"
    //         />
    //       )}
    //       <p className="w-48 md:text-xl text-black">{item.text}</p>
    //     </div>
    //   ))}
    // </div>

    <div className="slider">
      <div className="slide-track">
        {bottomItems.map((item, index) => (
          <div key={index} className="slide">
            {item.image && (
              <img src={urlFor(item.image)} alt={`bottom-item-${index}`} />
            )}
            <p>{item.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
