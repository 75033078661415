import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";
// import Navbar from "../navbar/Navbar";
import Footer from "../Home/homeSec9/Footer";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function Blog() {
  const [blogData, setBlogData] = useState(null); // State to hold fetched blog data
  const [pageData, setPageData] = useState(null);
  const [activeType, setActiveType] = useState("All"); // Track active type
  const [randomBlog, setRandomBlog] = useState(null); // Initialize randomBlog state
  const [blogsToShow, setBlogsToShow] = useState(9); // Number of blogs to display initially

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await client.fetch(`
          *[_type == 'blogs'][0]{
            ...
          }
        `);
        setBlogData(data);
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    const fetchPageData = async () => {
      try {
        const result = await client.fetch('*[_type == "pageContent"][0]');
        setPageData(result);
        console.log("pageNavData", result);
      } catch (error) {
        console.error("Error fetching data from Sanity:", error);
      }
    };
    if (blogData && blogData.blogData.length > 0) {
      const randomBlogIndex = Math.floor(
        Math.random() * blogData.blogData.length
      );
      setRandomBlog(blogData.blogData[randomBlogIndex]);
    }
    fetchPageData();

    fetchData(); // Fetch blog data on component mount
  }, []);


  useEffect(() => {
   
    if (blogData && blogData.blogData.length > 0) {
      const randomBlogIndex = Math.floor(
        Math.random() * blogData.blogData.length
      );
      setRandomBlog(blogData.blogData[randomBlogIndex]);
    }

  }, [blogData]);



  const handleResize = () => {
    // Update number of blogs to show based on screen size
    if (window.innerWidth < 768) {
      setBlogsToShow(3);
    } else {
      setBlogsToShow(9);
    }
  };

  useEffect(() => {
    // Listen for resize events
    window.addEventListener("resize", handleResize);

    // Call handleResize initially
    handleResize();

    // Clean up the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const filterBlogs = (type) => {
    setActiveType(type); // Set active type
  };

  const handleLoadMore = () => {
    // Increase the number of blogs to show by 3
    setBlogsToShow((prev) => prev + 3);
  };

  if (!blogData) return <div>Loading...</div>;

  return (
    <>
    <div className="blog-container px-[5%]">
      {/* Hero section */}
      {randomBlog && (
        <div className="blog-hero-page w-full flex md:flex-row flex-col items-center md:space-x-20 py-[5%]">
          <div className="blog-hero-left w-full md:px-0 md:w-3/5">
            <img
              src={urlFor(randomBlog.blogImage)}
              alt={randomBlog.type}
              className="w-full"
            />
          </div>
          <div className="blog-hero-right w-full md:w-2/5 flex flex-col items-start gap-4 py-8">
            <p className="text-[16px] font-semibold text-gray-500">
              {randomBlog.blogAuthor}
            </p>
            <p className="text-2xl md:text-3xl lg:text-5xl text-gray-900">
              {randomBlog.blogHeader}
            </p>
            <span className="text-base text-gray-700 font-normal">
              {randomBlog.blogReleaseDate} <strong>.</strong>{" "}
              {randomBlog.blogReleaseTime}
            </span>
          </div>
        </div>
      )}

      {/* Filter links */}
      <div className="filter-link flex md:flex-row flex-col items-start md:items-center justify-between  md:px-0">
        <h1 className="text-[32px] lg:text-6xl text-black font-bold md:w-2/5">
          {blogData.latestPost}
        </h1>
        <ul className="md:w-3/5 flex items-center gap-24 md:gap-8 md:space-x-16 w-full mt-8 md:mt-0 overflow-x-auto">
          {blogData.blogType.map((type, id) => (
            <li
              key={id}
              onClick={() => filterBlogs(type.type)}
              className={`border-b-2 w-40 md:w-56 text-xl text-center font-medium md:pb-2 pb-4 hover:cursor-pointer whitespace-nowrap ${
                activeType === type.type
                  ? "text-black border-black"
                  : "border-gray-500 text-gray-500"
              }`}
            >
              {type.type}
            </li>
          ))}
        </ul>
      </div>

      {/* Render filtered blogs */}
      <div className="blog-separate-box-container flex flex-wrap items-start justify-between my-20 mb-0">
        { pageData && blogData.blogData
          .filter((blog) => activeType === "All" || blog.type === activeType)
          .slice(0, blogsToShow)
          .map((blog) => (
            <Link
              to={`/${pageData.blog ? pageData.blog :'blog'}/${blog.path}`}
              key={blog.id}
              className="w-full md:w-[30%] flex flex-col items-start space-y-4 mb-12"
            >
              <div className="w-full">
                <img
                  src={urlFor(blog.blogImage)}
                  alt={blog.type}
                  className="w-full h-60"
                />
              </div>
              <div className="flex flex-col items-start space-y-4">
                <p>{blog.blogAuthor}</p>
                <p>{blog.blogHeader}</p>
                <span>
                  {blog.blogReleaseDate} <strong>.</strong>{" "}
                  {blog.blogReleaseTime}
                </span>
              </div>
            </Link>
          ))}
      </div>

      {/* Render Load More button */}
      {blogData.blogData.length > blogsToShow && (
        <button
          className="bg-gradient-to-r from-yellow-400 to-yellow-500 text-xl font-medium py-4 px-8 lg:ml-[50%] ml-[30%] rounded-xl my-12 capitalize"
          onClick={handleLoadMore}
        >
          Show More
        </button>
      )}
    </div>
    </>
  );
}

export default Blog;


// import React, { useState, useEffect } from "react";
// import { blogData } from "../utils/BlogData/mockData";
// import { Link } from "react-router-dom";

// function Blog() {
//   const [filteredBlogs, setFilteredBlogs] = useState(blogData.slice(1)); // Initially, all blogs are displayed
//   const [randomBlog, setRandomBlog] = useState(null); // Initialize randomBlog state
//   const [activeType, setActiveType] = useState("All"); // Track active type
//   const [blogsToShow, setBlogsToShow] = useState(9); // Number of blogs to display initially

//   useEffect(() => {

//     if (filteredBlogs.length > 0) {
//       const randomBlogIndex = Math.floor(Math.random() * filteredBlogs.length);
//       setRandomBlog(filteredBlogs[randomBlogIndex]);
//     }
//   }, [filteredBlogs]);

//   useEffect(() => {
//     // Update number of blogs to show based on screen size
//     const handleResize = () => {
//       if (window.innerWidth < 768) {
//         setBlogsToShow(3);
//       } else {
//         setBlogsToShow(9);
//       }
//     };

//     // Listen for resize events
//     window.addEventListener("resize", handleResize);

//     // Call handleResize initially
//     handleResize();

//     // Clean up the event listener
//     return () => window.removeEventListener("resize", handleResize);
//   }, []);

//   const filterBlogs = (type) => {
//     setActiveType(type); // Set active type
//     if (type === "All") {
//       setFilteredBlogs(blogData.slice(1)); // If "All" is selected, display all blogs
//     } else {
//       const filtered = blogData.slice(1).filter((blog) => blog.type === type);
//       setFilteredBlogs(filtered);
//     }
//   };

//   const handleLoadMore = () => {
//     // Increase the number of blogs to show by 3
//     setBlogsToShow((prev) => prev + 3);
//   };

//   return (
//     <div className="blog-container px-[5%]">
//       {randomBlog && ( // Only render randomBlog if it exists
//         <div
//           key={randomBlog.id}
//           className="blog-hero-page w-full flex md:flex-row flex-col items-center md:space-x-20 py-[5%]"
//         >
//           <div className="blog-hero-left w-full md:px-0 md:w-3/5">
//             <img
//               src={randomBlog.blogImage}
//               alt={randomBlog.type}
//               className="w-full"
//             />
//           </div>
//           <div className="blog-hero-right w-full md:w-2/5 flex flex-col items-start gap-4 py-8">
//             <p className="text-[16px] font-semibold text-gray-500">
//               {randomBlog.authorName}
//             </p>
//             <p className="text-2xl md:text-3xl lg:text-5xl text-gray-900">
//               {randomBlog.header}
//             </p>
//             <span className="text-base text-gray-700 font-normal">
//               {randomBlog.releaseDate}{" "} <strong>.</strong>{" "}
//               {randomBlog.releaseTime}
//             </span>
//           </div>
//         </div>
//       )}
//       <div className="filter-link flex md:flex-row flex-col items-start md:items-center justify-between  md:px-0">
//         <h1 className="text-[32px] lg:text-6xl text-black font-bold md:w-2/5">
//           {blogData[0]?.latestPost}
//         </h1>
//         <ul className="md:w-3/5 flex items-center gap-24 md:gap-8 md:space-x-16 w-full mt-8 md:mt-0 overflow-x-auto">
//           {blogData[0]?.blogTypes &&
//             blogData[0]?.blogTypes.map((type, id) => (
//               <li
//                 key={id}
//                 onClick={() => filterBlogs(type)}
//                 className={`border-b-2 w-40 md:w-56 text-xl text-center font-medium md:pb-2 pb-4 hover:cursor-pointer whitespace-nowrap ${
//                   activeType === type
//                     ? "text-black border-black"
//                     : "border-gray-500 text-gray-500"
//                 }`}
//               >
//                 {type}
//               </li>
//             ))}
//         </ul>
//       </div>
//       <div className="blog-separate-box-container flex flex-wrap items-start justify-between my-20 mb-0">
//         {filteredBlogs.slice(0, blogsToShow).map((blogs, id) => (
//           <Link
//             to={`/blog/${blogs.path}`}
//             key={id}
//             className="w-full md:w-[30%] flex flex-col items-start space-y-4 mb-12"
//           >
//             <div className="w-full">
//               <img
//                 src={blogs.blogImage}
//                 alt={blogs.type}
//                 className="w-full h-60"
//               />
//             </div>
//             <div className="flex flex-col items-start space-y-4">
//               <p>{blogs.authorName}</p>
//               <p>{blogs.header}</p>
//               <span>
//                 {blogs.releaseDate} {" "}<strong>.</strong> {blogs.releaseTime}
//               </span>
//             </div>
//           </Link>
//         ))}
//       </div>
//       {filteredBlogs.length > blogsToShow && ( // Render Load More button if there are more blogs to show
//         <button
//           className="bg-gradient-to-r from-yellow-400 to-yellow-500 text-xl font-medium py-4 px-8 lg:ml-[50%] ml-[30%] rounded-xl my-12 capitalize"
//           onClick={handleLoadMore}
//         >
//           Show More
//         </button>
//       )}
//     </div>
//   );
// }

// export default Blog;
