import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import client from "../../sanityCli"; // Assuming `client` is imported from your Sanity client setup
import imageUrlBuilder from "@sanity/image-url";
import ReviewComment from "./ReviewComment";
import Navbar from "../navbar/Navbar";
import Footer from "../Home/homeSec9/Footer";


const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function ReviewDetailPage() {
  const [review, setReview] = useState(null);
  const [pageData, setPageData] = useState(null);
  const { id } = useParams();
  let path = id;

  console.log('path', path)

  useEffect(() => {
    client
      // .fetch(`*[_type == "review" ]`, { id })
      .fetch(`
      *[_type == 'review'][0]{
        ...
      }
    `)
      .then((data) => {
        data.relatedReviews = data.reviewCardData.filter(r => r.path != path);
        setReview(data);
        console.log('data', data);
      })
      .catch((error) => {
        console.error("Error fetching review:", error);
      });

      const fetchPageData = async () => {
        try {
          const result = await client.fetch('*[_type == "pageContent"][0]');
          setPageData(result);
          console.log("pageNavData", result);
        } catch (error) {
          console.error("Error fetching data from Sanity:", error);
        }
      };
      fetchPageData();
      window.scrollTo(0, 0)
  }, [path]);

  if (!review) {
    return <div>Loading...</div>;
  }

  

  return (
    <>
    <Navbar />
    <div className="review-detail-page relative">
      <div className="background-color bg-amber-300 h-[400px] flex items-center justify-center relative">
        <img
          src={urlFor(review.reviewHeroData.heroImage)}
          alt="Review Hero"
          className="overlay-image w-[90%] h-[360px] lg:h-[520px] xl:h-[560px] absolute top-32 lg:top-12 inset-0 mx-auto rounded-lg"
        />
      </div>
      <div className="review-details px-[5%] py-10 mt-20 md:mt-32 lg:mt-64 xl:mt-80">
        <div className="flex items-center justify-between">
          <div className="flex items-star">
            <div className="dev-image w-20 h-20 rounded-full">
              <img
                src={urlFor(review.reviewCardData.find(r => r.path === path).developerDetail.developerImage)}
                alt={review.reviewCardData.find(r => r.path === path).developerDetail.developerName}
                className="dev-image w-16 h-16 rounded-full"
              />
            </div>
            <div className="flex flex-col space-y-1">
              <p className="text-xl font-semibold text-capitalize">
                {review.reviewCardData.find(r => r.path === path).developerDetail.developerName}
              </p>
              <p>{review.reviewCardData.find(r => r.path === path).developerDetail.role}</p>
            </div>
          </div>
          <div className="social-media flex item-center space-x-4">
            {review.reviewCardData.find(r => r.path === path).developerDetail.socialMediaLink.map(
              (socialIcon, index) => (
                <img
                  key={index}
                  src={urlFor(socialIcon.icon)}
                  alt="Social Media Icon"
                  className="w-6"
                />
              )
            )}
          </div>
        </div>
        <div className="game-info flex items-start justify-evenly my-8 py-4 lg:py-10 border-y-2 overflow-x-auto">
          {review.reviewCardData.find(r => r.path === path).gameInfo.map((gameDetails, index) => (
            <div
              key={index}
              className={`flex-shrink-0 flex flex-col items-center pr-16 space-y-2 ${
                index !== review.reviewCardData.find(r => r.path === path).gameInfo.length - 1
                  ? "border-r-2"
                  : ""
              } w-48`}
            >
              <p className="text-2xl text-gray-400">{gameDetails.position}</p>
              <img
                src={urlFor(gameDetails.icon)}
                alt={gameDetails.name}
                className="w-6 h-6 rounded-full"
              />
              <p className="text-xl font-medium text-gray-400 text-center">
                {gameDetails.name}
              </p>
            </div>
          ))}
        </div>
        <div className="game-name-rating flex items-center justify-between py-4 px-8 my-12 bg-gradient-to-r from-yellow-500 to-yellow-300 rounded-2xl">
          <p className="text-3xl text-white font-medium">
            {review.reviewCardData[0].gameName}
          </p>
          <p className="text-white text-3xl">
            {review.reviewCardData.find(r => r.path === path).gameRating}
          </p>
        </div>
        <div className="mt-20">
          {review.reviewCardData.find(r => r.path === path).reviewDetailtextContent.map((content, index) => (
            <div key={index}>
              <h1 className="text-4xl font-semibold font-montserrat leading-tight">
                {content.header}
              </h1>
              {content.description.map((desc, idx) => (
                <p
                  key={idx}
                  className="my-8 text-xl font-normal font-montserrat leading-tight"
                >
                  {desc}
                </p>
              ))}
              {index === 0 && (
                <div className="w-full flex items-center justify-center my-20">
                  <img
                    src={urlFor(review.reviewCardData.find(r => r.path === path).reviewCardImage)}
                    alt="Review"
                    className="w-full"
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="related-reviews w-full flex md:flex-row flex-col items-start gap-12 md:space-x-4 lg:space-x-12 px-[5%] lg:my-24 mb-20">
        {pageData && review.relatedReviews && review.relatedReviews.slice(0, 3).map((relatedReview, index) => (
          <Link
            to={`/${pageData.reviews ? pageData.reviews : 'reviews'}/${relatedReview.path}`}
            className="lg:w-1/3 w-full flex items-center space-x-4"
            key={index}
          >
            <div className="w-full flex flex-col space-y-4">
              <img
                src={urlFor(relatedReview.reviewCardImage)}
                alt={relatedReview.authorName}
                className="rounded-2xl"
              />
              <div className="flex flex-col md:items-start items-center ">
                <div className="flex items-center space-x-7 mb-4">
                  <p className="bg-amber-400 text-lg font-medium text-center py-2 px-4 rounded-sm">
                    {relatedReview.authorName}
                  </p>
                  <p className="text-xl text-black">{relatedReview.releaseTime}</p>
                </div>
                <div className="text-center md:text-start">
                  <strong>{relatedReview.reviewDetailtextContent[0].header}</strong>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
      <div className="review-rating-card hidden pl-12 pr-8 pb-12 lg:flex items-start bg-white mx-20 my-20 py-20 rounded-xl border-2">
        <div className="w-2/3 flex-col items-start">
          <div className="mb-12">
            <strong className="text-4xl text-black font-bold">
              {review.reviewCardData.find(r => r.path === path).reviewRating.reviewName}
              {/* Access review rating gameName */}
            </strong>
          </div>
          <div className="flex items-center space-x-20">
            <div className="w-1/4 flex-col items-start justify-between space-y-3">
              {/* Display other review rating details */}
              <p className="bg-amber-500 w-40 h-40 rounded-full text-center text-8xl text-white font-bold pt-8">
                {review.reviewCardData.find(r => r.path === path).reviewRating.ratingNumber}
              </p>
              <p className="bg-black p-4  rounded-full text-white text-2 xl font-semibold w-fit">
                {review.reviewCardData.find(r => r.path === path).reviewRating.choice}
              </p>
             { pageData && <Link to={`/${pageData.reviews ? pageData.reviews : 'reviews'}/${review.reviewCardData.find(r => r.path === path).path}/reviewRating`} state={review.reviewCardData.find(r => r.path === path)} className="text-amber-500 text-2xl font-semibold">
                {review.reviewCardData.find(r => r.path === path).reviewRating.reviewScore}
              </Link>
}
            </div>
            <div className="w-3/4">
              <p className="text-5xl text-black font-bold">
                {review.reviewCardData.find(r => r.path === path).reviewRating.title}
              </p>
              <p className="text-2xl text-gray-700 font-normal mt-5">
                {review.reviewCardData.find(r => r.path === path).reviewRating.subTitle}
              </p>
            </div>
          </div>
        </div>
        <div className="w-1/3">
          <img
            className="w-full h-full rounded-3xl"
            src={urlFor(review.reviewCardData.find(r => r.path === path).reviewRating.reviewImage)}
            alt={review.reviewCardData.find(r => r.path === path).reviewRating.gameName}
          />
        </div>
      </div>
      <ReviewComment />
    </div>
    <Footer />
    </>
  );
}

export default ReviewDetailPage;


// import { useEffect } from "react";
// import { useParams, Link } from "react-router-dom";
// import { reviewsData } from "../utils/ReviewData/mockData";
// import { crouselCards } from "../utils/assets/image";

// function ReviewDetailPage() {
//   const { id } = useParams();
//   const review = reviewsData.find((review) => review.id === parseInt(id));

//   const relatedReviews = reviewsData.filter(
//     (item) => item.title === review.title
//   );

//   const limitedReviews = relatedReviews.slice(0, 3);

//   useEffect(() => {
//     window.scrollTo(0, 0); // Scroll to the top of the page when component mounts or updates
//   }, [id]); // Trigger effect when id changes

//   return (
//     <div className="review-detail-page relative">
//       <div className="background-color bg-amber-300 h-[400px] flex items-center justify-center relative">
//         <img
//           src={crouselCards[4]}
//           className="overlay-image w-[90%] h-[360px] lg:h-[520px] xl:h-[560px] absolute top-32 lg:top-12 inset-0 mx-auto rounded-lg"
//         />
//       </div>
//       <div className="review-details px-[5%] py-10  mt-20 md:mt-32 lg:mt-64  xl:mt-80">
//         <div key={id} className="flex items-center justify-between">
//           <div className="flex items-star">
//             <div className="dev-image w-20 h-20 rounded-full">
//               <img
//                 src={review.developerDetail.developerImage}
//                 alt={review.developerDetail.developerName}
//                 className="dev-image w-16 h-16 rounded-full"
//               />
//             </div>
//             <div className="flex flex-col space-y-1">
//               <p className="text-xl font-semibold text-capitlize">
//                 {review.developerDetail.developerName}
//               </p>
//               <p>{review.developerDetail.role}</p>
//             </div>
//           </div>
//           <div className="social-media flex item-center space-x-4">
//             {review.developerDetail.socialMediaLink.map((socialIcon) => (
//               <img
//                 key={socialIcon.id}
//                 src={socialIcon.icon}
//                 alt="/"
//                 className="w-6"
//               />
//             ))}
//           </div>
//         </div>
//         <div className="game-info flex items-start justify-evenly my-8 py-4 lg:py-10 border-y-2 overflow-x-auto">
//           {review.gameInfo.map((gameDetails, index) => (
//             <div
//               key={gameDetails.id}
//               className={`flex-shrink-0 flex flex-col items-center pr-16 space-y-2 ${
//                 index !== review.gameInfo.length - 1 ? "border-r-2" : ""
//               } w-48`} // Set width using Tailwind CSS
//             >
//               <p className="text-2xl text-gray-400">{gameDetails.position}</p>
//               <img
//                 src={gameDetails.icon}
//                 alt={gameDetails.name}
//                 className="w-6 h-6 rounded-full"
//               />
//               <p className="text-xl font-medium text-gray-400 text-center">
//                 {gameDetails.name}
//               </p>
//             </div>
//           ))}
//         </div>

//         <div className="game-name-rating flex items-center justify-between py-4 px-8 my-12 bg-gradient-to-r from-yellow-500 to-yellow-300 rounded-2xl">
//           <p className="text-3xl text-white font-medium">{review.gameName}</p>
//           <p className="text-white text-3xl">{review.gameRating}</p>
//         </div>
//         <div className="mt-20">
//           {review.textContent.map((content, index) => (
//             <div key={index}>
//               <h1 className="text-4xl font-semibold font-montserrat leading-tight">
//                 {content.header}
//               </h1>
//               {content.description.map((desc, idx) => (
//                 <p
//                   key={idx}
//                   className="my-8 text-xl font-normal font-montserrat leading-tight"
//                 >
//                   {desc}
//                 </p>
//               ))}
//               {index === 0 && (
//                 <div className="w-full flex items-center justify-center my-20">
//                   <img src={review.image} alt="Review" className="w-full" />
//                 </div>
//               )}
//             </div>
//           ))}
//         </div>
//       </div>
//       <div className="related-reviews w-full flex md:flex-row flex-col items-start gap-12 md:space-x-4 lg:space-x-12 px-[5%] lg:my-24 mb-20">
//         {limitedReviews.map((relatedReview, index) => (
//           <Link
//             to={`/reviews/${relatedReview.id}`} // Use relatedReview.id instead of review.id
//             className="lg:w-1/3 w-full flex items-center space-x-4"
//             key={index} // Move the key to the Link component
//           >
//             <div key={index} className="w-full flex flex-col space-y-4">
//               <img
//                 src={relatedReview.image}
//                 alt={relatedReview.name}
//                 className="rounded-2xl"
//               />
//               <div className="flex flex-col md:items-start items-center ">
//                 <div className="flex items-center space-x-7 mb-4">
//                   <p className="bg-amber-400 text-lg font-medium text-center py-2 px-4 rounded-sm">
//                     {review.name}
//                   </p>
//                   <p className="text-xl text-black">{review.time}</p>
//                 </div>
//                 <div className="text-center md:text-start">
//                   <strong>{relatedReview.textContent[0].header}</strong>
//                 </div>
//               </div>
//             </div>
//           </Link>
//         ))}
//       </div>
      // <div className="review-rating-card hidden pl-12 pr-8 pb-12 lg:flex items-start bg-white mx-20 my-20 py-20 rounded-xl border-2">
      //   <div className="w-2/3 flex-col items-start">
      //     <div className="mb-12">
      //       <strong className="text-4xl text-black font-bold">
      //         {review.reviewRating.gameName}{" "}
      //         {/* Access review rating gameName */}
      //       </strong>
      //     </div>
      //     <div className="flex items-center space-x-20">
      //       <div className="w-1/4 flex-col items-start justify-between space-y-3">
      //         {/* Display other review rating details */}
      //         <p className="bg-amber-500 w-40 h-40 rounded-full text-center text-8xl text-white font-bold pt-8">
      //           {review.reviewRating.ratingNumber}
      //         </p>
      //         <p className="bg-black p-4  rounded-full text-white text-2 xl font-semibold w-fit">
      //           {review.reviewRating.choice}
      //         </p>
      //         <Link to={`/reviews/${review.id}/reviewRating`} className="text-amber-500 text-2xl font-semibold">
      //           {review.reviewRating.reviewScore}
      //         </Link>
      //       </div>
      //       <div className="w-3/4">
      //         <p className="text-5xl text-black font-bold">
      //           {review.reviewRating.title}
      //         </p>
      //         <p className="text-2xl text-gray-700 font-normal mt-5">
      //           {review.reviewRating.subTitle}
      //         </p>
      //       </div>
      //     </div>
      //   </div>
      //   <div className="w-1/3">
      //     <img
      //       className="w-full h-full rounded-3xl"
      //       src={review.reviewRating.reviewImage}
      //       alt={review.reviewRating.gameName}
      //     />
      //   </div>
      // </div>
//     </div>
//   );
// }

// export default ReviewDetailPage;
