import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../../sanityCli";
import imageUrlBuilder from "@sanity/image-url";
import Footer from "../Home/homeSec9/Footer";
import Navbar from "../navbar/Navbar";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

function ReviewPage() {
  const [reviewHeroData, setReviewHeroData] = useState(null);
  const [reviewCardData, setReviewCardData] = useState(null);
  const [filteredReviewsData, setFilteredReviewsData] = useState([])
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [startIndex, setStartIndex] = useState(0);
  const reviewsPerPage = 2;
  const reviewsPerPageLink = 5;

  // let filteredReviewsData = filterCriteria
  // ? reviewCardData.filter((review) => review.filterText === filterCriteria)
  // : reviewCardData;


  useEffect(() => {
    // Fetch review hero data
    client
      .fetch(`*[_type == 'review'][0].reviewHeroData`)
      .then((data) => setReviewHeroData(data))
      .catch((error) =>
        console.error("Error fetching review hero data:", error)
      );


      const fetchPageData = async () => {
        try {
          const result = await client.fetch('*[_type == "pageContent"][0]');
          setPageData(result);
          console.log("pageNavData", result);
        } catch (error) {
          console.error("Error fetching data from Sanity:", error);
        }
      };
      fetchPageData();

    // Fetch review card data
    client
      .fetch(`*[_type == 'review'][0].reviewCardData`)
      .then((data) =>{
        setReviewCardData(data);
        if(!filterCriteria)
        setFilteredReviewsData(data.sort((a, b) => new Date(b.reviewTime) - new Date(a.reviewTime)));

      } )
      .catch((error) =>
        console.error("Error fetching review card data:", error)
      );
      // setFilteredReviewsData(reviewCardData.sort((a, b) => new Date(b.reviewTime) - new Date(a.reviewTime)));
  }, []);

  if (!reviewHeroData || !reviewCardData) {
    return <div>Loading...</div>;
  }

  const filterReviewsByTitle = (title) => {
    setFilteredReviewsData(reviewCardData.filter((review) => review.filterText === title))
    console.log('editor', title)
    setFilterCriteria(title);
    setStartIndex(0);
  };

 
  const filterReviewByTime = (reviews, title) => {
    setFilteredReviewsData(reviews.sort((a, b) => new Date(b.reviewTime) - new Date(a.reviewTime)));
    // isActive(title)
    setFilterCriteria(title)
    
  }

  const totalPages = Math.ceil(
    (filteredReviewsData?.length || 0) / reviewsPerPage
  );
  const currentPage = Math.floor(startIndex / reviewsPerPage) + 1;

  const isActive = (title) => {
    return title === filterCriteria;
  };

  const handleNextPage = () => {
    const nextIndex = startIndex + reviewsPerPage;
    setStartIndex(
      nextIndex >= (filteredReviewsData?.length || 0) ? startIndex : nextIndex
    );
  };

  const handlePrevPage = () => {
    const prevIndex = startIndex - reviewsPerPage;
    setStartIndex(prevIndex < 0 ? 0 : prevIndex);
  };

  return (
    <>
    <Navbar />
    <div className="reviews-container w-full  p-6 lg:p-20">
      <div className="flex md:flex-col flex-col-reverse items-center gap-8">
        <div className="flex flex-col items-center">
          <h1 className="text-center font-montserrat text-2xl md:text-3xl lg:text-5xl font-bold uppercase tracking-widest">
            {reviewHeroData.title}
          </h1>
          <p className="text-center font-poppins text-xl lg:text-3xl xl:w-[70%] font-normal leading-relaxed tracking-wide lg:mt-8 mt-4">
            {reviewHeroData.subTitle}
          </p>
        </div>
        <img
          src={urlFor(reviewHeroData.heroImage)}
          alt="review-hero-image"
          className="md:h-[660px] h-72 w-full rounded-xl"
        />
      </div>
      <div className="top-reviews-container mt-10">
        <h1 className="font-montserrat text-2xl md:text-4xl text-black lg:text-5xl 2xl:text-7xl font-semibold tracking-tight leading-normal uppercase text-center lg:text-start">
          Top Reviews
        </h1>
        <div className="filtered-list flex items-center space-x-5 my-8">
          <button
            className={`w-64 py-3 text-center uppercase relative text-base font-medium  ${
              isActive(reviewHeroData.btnLatest)
                ? "text-[20px] font-semibold"
                : ""
            }`}
            onClick={() => filterReviewByTime(reviewCardData, reviewHeroData.btnLatest)}
          >
            <span className="relative z-10 uppercase">
              {" "}
              {reviewHeroData.btnLatest}
            </span>
            <span
              className={`absolute top-0 left-0 right-0 bottom-0 ${
                isActive(reviewHeroData.btnLatest)
                  ? "text-gray-900 bg-gradient-to-r from-yellow-500 to-yellow-300 transform skew-x-[-20deg] z-0"
                  : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform skew-x-[-20deg] z-0"
              }`}
            ></span>
          </button>
          <button
            className={`w-64 py-3 text-center uppercase relative text-base font-medium ${
              isActive(reviewHeroData.btnEditor)
                ? "text-[20px] font-semibold"
                : ""
            }`}
            onClick={() => filterReviewsByTitle(reviewHeroData.btnEditor)}
          >
            <span className="relative z-10 uppercase">
              {reviewHeroData.btnEditor}
            </span>
            <span
              className={`absolute top-0 left-0 right-0 bottom-0 ${
                isActive(reviewHeroData.btnEditor)
                  ? "text-gray-900 bg-gradient-to-r from-yellow-500 to-yellow-300 transform skew-x-[-20deg] z-0"
                  : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform skew-x-[-20deg] z-0"
              }`}
            ></span>
          </button>
        </div>
        <section className="review-box-container flex items-start space-x-20 py-20">
          <div className="md:w-1/2 w-full review-box">
            {pageData && filteredReviewsData
              .slice(startIndex, startIndex + reviewsPerPage)
              .map((review, index) => (
                <div key={review.id}>
                  <Link
                    to={`/${pageData.reviews ? pageData.reviews : 'reviews'}/${review.path}`}
                    className=" flex flex-col md:items-start items-center space-y-5 mb-8"
                  >
                    <img
                      src={urlFor(review.reviewCardImage.asset)}
                      alt={review.authorName}
                      className="w-full rounded-2xl"
                    />
                    <div className="flex items-center space-x-4">
                      <p className="bg-amber-400 text-xl font-semibold text-center py-2 px-4 rounded-sm">
                        {review.authorName}
                      </p>
                      <p className="text-xl text-black">{review.releaseTime}</p>
                    </div>
                    <p className="text-xl md:text-3xl lg:text-5xl text-center md:text-start text-gray-800 font-semibold">
                      {review.reviewDetailtextContent[0].header}
                    </p>
                    <p className="text-xl text-gray-900 font-normal text-center md:text-start">
                      {review.reviewDetailtextContent[0].description[0]}
                    </p>
                  </Link>
                </div>
              ))}
          </div>
          <div className="review-box-link flex-col w-1/2 md:block hidden">
            {pageData && filteredReviewsData
              .slice(startIndex, startIndex + reviewsPerPageLink)
              .map((review, index) => (
                <div key={review.id}>
                  <Link
                    to={`/${pageData.reviews ? pageData.reviews : 'reviews'}/${review.path}`}
                    className="w-full flex items-center space-x-4 mb-24"
                  >
                    <img
                      src={urlFor(review.reviewCardImage)}
                      alt={review.authorName}
                      className="w-1/2 rounded-2xl"
                    />
                    <div className="flex flex-col items-start space-y-4">
                      <div className="flex items-center space-x-4">
                        <p className="bg-amber-400 text-lg font-medium text-center py-2 px-4 rounded-sm">
                          {review.authorName}
                        </p>
                        <p className="text-xl text-black">
                          {review.releaseTime}
                        </p>
                      </div>
                      <p className="text-xl text-gray-800 font-semibold">
                        {review.reviewDetailtextContent[0].header}
                      </p>
                    </div>
                  </Link>
                </div>
              ))}
          </div>
        </section>
        <div className="flex justify-center space-x-8 mt-8">
          <button
            onClick={handlePrevPage}
            disabled={startIndex === 0}
            className="bg-yellow-500 text-gray-800 text-xl py-2 px-4 rounded-md"
          >
            {reviewHeroData.btnPrevious}
          </button>
          <button
            onClick={handleNextPage}
            disabled={startIndex + reviewsPerPage >= filteredReviewsData.length}
            className="bg-yellow-500 text-gray-800 text-xl  py-2 px-4 rounded-md"
          >
            {reviewHeroData.btnNext}
          </button>
        </div>
      </div>
      {/* <ReviewComment /> */}
    </div>
    <Footer />
    </>
  );
}

export default ReviewPage;

// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { crouselCards } from "../utils/assets/image";
// import { reviewsData } from "../utils/ReviewData/mockData";

// function ReviewPage() {
//   const [filterCriteria, setFilterCriteria] = useState(null);
//   const [startIndex, setStartIndex] = useState(0);
//   const reviewsPerPage = 2; // Number of reviews to show per page in review-box
//   const reviewsPerPageLink = 5; // Number of reviews to show per page in review-box-link

//   const filterReviewsByTitle = (title) => {
//     setFilterCriteria(title);
//     setStartIndex(0);
//   };

//   const filteredReviewsData = filterCriteria
//     ? reviewsData.filter((review) => review.title === filterCriteria)
//     : reviewsData;

//   const totalPages = Math.ceil(filteredReviewsData.length / reviewsPerPage);
//   const currentPage = Math.floor(startIndex / reviewsPerPage) + 1;

//   const isActive = (title) => {
//     return title === filterCriteria;
//   };

//   const handleNextPage = () => {
//     const nextIndex = startIndex + reviewsPerPage;
//     setStartIndex(
//       nextIndex >= filteredReviewsData.length ? startIndex : nextIndex
//     );
//   };

//   const handlePrevPage = () => {
//     const prevIndex = startIndex - reviewsPerPage;
//     setStartIndex(prevIndex < 0 ? 0 : prevIndex);
//   };

//   return (
//     <div className="reviews-container w-full  p-6 lg:p-20">
//       <div className="flex md:flex-col flex-col-reverse items-center gap-8">
//         <div className="flex flex-col items-center">
//           <h1 className="text-center font-montserrat text-2xl md:text-3xl lg:text-5xl font-bold uppercase tracking-widest">
//             LOREM IPSUM DOLOR SIT AMET
//           </h1>
//           <p className="text-center font-poppins text-xl lg:text-3xl xl:w-[70%] font-normal leading-relaxed tracking-wide lg:mt-8 mt-4">
//             Lorem Ipsum is simply dummy text of the printing and typesetting
//             industry. Lorem Ipsum has been the industry's standard dummy text
//             ever since the 1500s,
//           </p>
//         </div>
//         {/* Hero image */}
//         <img
//           src={crouselCards[4]}
//           alt="review-hero-image"
//           className="md:h-[660px] h-72 w-full rounded-xl"
//         />
//       </div>
//       {/* Top reviews section */}
//       <div className="top-reviews-container mt-10">
//         {/* Section title */}
//         <h1 className="font-montserrat text-2xl md:text-4xl lg:text-5xl 2xl:text-7xl font-semibold tracking-tight leading-normal uppercase text-center lg:text-start">
//           top reviews
//         </h1>
//         {/* Filter buttons */}
//         <div className="filtered-list flex items-center space-x-5 my-8">
//           <button
//             className={`w-64 py-3 text-center uppercase relative text-base font-medium  ${
//               isActive("Latest") ? "text-[20px] font-semibold" : ""
//             }`}
//             onClick={() => filterReviewsByTitle("Latest")}
//           >
//             <span className="relative z-10 uppercase">Latest 🔥</span>
//             <span
//               className={`absolute top-0 left-0 right-0 bottom-0 ${
//                 isActive("Latest")
//                   ? "text-gray-900 bg-gradient-to-r from-yellow-500 to-yellow-300 transform skew-x-[-20deg] z-0"
//                   : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform skew-x-[-20deg] z-0"
//               }`}
//             ></span>
//           </button>
//           <button
//             className={`w-64 py-3 text-center uppercase relative text-base font-medium ${
//               isActive("Editor choice") ? "text-[20px] font-semibold" : ""
//             }`}
//             onClick={() => filterReviewsByTitle("Editor choice")}
//           >
//             <span className="relative z-10 uppercase">editor's choice ❤️</span>
//             <span
//               className={`absolute top-0 left-0 right-0 bottom-0 ${
//                 isActive("Editor choice")
//                   ? "text-gray-900 bg-gradient-to-r from-yellow-500 to-yellow-300 transform skew-x-[-20deg] z-0"
//                   : "absolute top-0 left-0 right-0 bottom-0 border-[1px] border-yellow-500 transform skew-x-[-20deg] z-0"
//               }`}
//             ></span>
//           </button>
//         </div>
//         {/* Review boxes */}
//         <section className="review-box-container flex items-start space-x-20 py-20">
//           {/* review-box */}
//           <div className="md:w-1/2 w-full review-box">
//             {filteredReviewsData
//               .slice(startIndex, startIndex + reviewsPerPage)
//               .map((review, index) => (
//                 <div key={review.id}>
//                   <Link
//                     to={`/reviews/${review.id}`}
//                     className=" flex flex-col md:items-start items-center space-y-5 mb-8"
//                   >
//                     {/* Review content */}
//                     <img
//                       src={review.image}
//                       alt={review.name}
//                       className="w-full rounded-2xl"
//                     />
//                     <div className="flex items-center space-x-4">
//                       <p className="bg-amber-400 text-xl font-semibold text-center py-2 px-4 rounded-sm">
//                         {review.name}
//                       </p>
//                       <p className="text-xl text-black">{review.time}</p>
//                     </div>
//                     <p className=" text-xl md:text-3xl lg:text-5xl text-center md:text-start text-gray-800 font-semibold">
//                       {review.textContent[0].header}
//                     </p>
//                     <p className="text-xl text-gray-900 font-normal text-center md:text-start">
//                       {review.textContent[0].description[0]}
//                     </p>
//                   </Link>
//                 </div>
//               ))}
//           </div>
//           {/* review-box-link */}
//           <div className="review-box-link flex-col w-1/2 md:block hidden">
//             {filteredReviewsData
//               .slice(startIndex, startIndex + reviewsPerPageLink)
//               .map((review, index) => (
//                 <div key={review.id}>
//                   <Link
//                     to={`/reviews/${review.id}`}
//                     className="w-full flex items-center space-x-4 mb-24"
//                   >
//                     <img
//                       src={review.image}
//                       alt={review.name}
//                       className="w-1/2 rounded-2xl"
//                     />
//                     <div className="flex flex-col items-start space-y-4">
//                       <div className="flex items-center space-x-4">
//                         <p className="bg-amber-400 text-lg font-medium text-center py-2 px-4 rounded-sm">
//                           {review.name}
//                         </p>
//                         <p className="text-xl text-black">{review.time}</p>
//                       </div>
//                       <p className="text-xl text-gray-800 font-semibold">
//                         {review.textContent[0].header}
//                       </p>
//                     </div>
//                   </Link>
//                 </div>
//               ))}
//           </div>
//         </section>
//         {/* Next and Previous buttons */}
//         <div className="flex justify-center space-x-8 mt-8">
//           <button
//             onClick={handlePrevPage}
//             disabled={startIndex === 0}
//             className="bg-yellow-500 text-gray-800 text-xl py-2 px-4 rounded-md"
//           >
//             Previous
//           </button>
//           <button
//             onClick={handleNextPage}
//             disabled={startIndex + reviewsPerPage >= filteredReviewsData.length}
//             className="bg-yellow-500 text-gray-800 text-xl  py-2 px-4 rounded-md"
//           >
//             Next
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default ReviewPage;
