import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import imageUrlBuilder from "@sanity/image-url";
import client from "../../sanityCli";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const [navbarData, setNavbarData] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const navbarResult = await client.fetch('*[_type == "navbarContent"][0]');
        setNavbarData(navbarResult);
      } catch (error) {
        console.error("Error fetching navbar data from Sanity:", error);
      }

      try {
        const pageResult = await client.fetch('*[_type == "pageContent"][0]');
        setPageData(pageResult);
      } catch (error) {
        console.error("Error fetching page data from Sanity:", error);
      }
    };

    fetchData();
  }, []);

  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };

    const handleNavLinkClick = (sectionId) => {
        console.log("section", sectionId);
        if (location.pathname !== "/") {
          // Navigate to the home page first
          navigate("/");
          // After navigating to home, scroll to the specified section
          setTimeout(() => {
            const element = document.getElementById(sectionId);
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }, 500); // Adjust the delay time as needed
        } else {
          // Scroll to the specified section with smooth behavior
          const element = document.getElementById(sectionId);
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }
        // Close mobile menu if it's open
        closeMobileMenu();
      };

  return (
    <nav className="navbar flex items-center justify-between py-5 px-6 md:px-10 lg:px-20 2xl:px-28 bg-white shadow-md">
      <div className="navbar-logo">
        {navbarData && <img src={urlFor(navbarData.logo)} alt="logo-image" />}
      </div>
      <div className="md:hidden hamburger-button">
        <button onClick={toggleMobileMenu}>
          {navbarData && <img src={urlFor(navbarData.hamburgerBtn)} />}
        </button>
      </div>
      {isMobileMenuOpen && (
        <div className="mobile-menu md:hidden absolute top-0 right-0 z-10 bg-white p-4 w-full shadow-lg">
          <div className="flex flex-col gap-4 pt-10 pb-2 px-4 text-3xl">
            {navbarData && (
              <p
                onClick={() => handleNavLinkClick("whatWeDo")}
                className="text-black uppercase text-sm cursor-pointer"
              >
                {navbarData.whatWeDo}
              </p>
            )}
            {navbarData && (
              <p
                onClick={() => handleNavLinkClick("topGames")}
                className="text-black uppercase text-sm cursor-pointer"
              >
                {navbarData.topGames}
              </p>
            )}
            {navbarData && pageData && (
              <Link
                to={`/${pageData.blog ? pageData.blog : "blog"}`}
                className="text-black uppercase text-sm cursor-pointer"
              >
                {navbarData.blog}
              </Link>
            )}
            {navbarData && (
              <p
                onClick={() => handleNavLinkClick("about")}
                className="text-black uppercase text-sm cursor-pointer"
              >
                {navbarData.aboutUs}
              </p>
            )}
            {navbarData && (
              <p
                onClick={() => handleNavLinkClick("team")}
                className="text-black uppercase text-sm cursor-pointer"
              >
                {navbarData.teams}
              </p>
            )}
            {navbarData && (
              <Link
                to={`/${pageData && pageData.reviews ? pageData.reviews : "reviews"}`}
                className="text-black uppercase text-sm cursor-pointer"
              >
                {navbarData.reviews}
              </Link>
            )}
            {navbarData && (
              <p
                onClick={() => handleNavLinkClick("contact-us")}
                className="text-black uppercase text-sm bg-yellow-500 p-3 rounded-xl cursor-pointer"
              >
                {navbarData.contact}
              </p>
            )}
          </div>
        </div>
      )}
      <div className="hidden md:flex items-center space-x-4 xl:space-x-8">
        {navbarData && (
          <p
            onClick={() => handleNavLinkClick("whatWeDo")}
            className="text-black uppercase text-base cursor-pointer"
          >
            {navbarData.whatWeDo}
          </p>
        )}
        {navbarData && (
          <p
            onClick={() => handleNavLinkClick("topGames")}
            className="text-black uppercase text-base cursor-pointer"
          >
            {navbarData.topGames}
          </p>
        )}
        {navbarData && pageData && (
          <Link
            to={`/${pageData.blog ? pageData.blog : "blog"}`}
            className="text-black uppercase text-base cursor-pointer"
          >
            {navbarData.blog}
          </Link>
        )}
        {navbarData && (
          <p
            onClick={() => handleNavLinkClick("about")}
            className="text-black uppercase text-base cursor-pointer"
          >
            {navbarData.aboutUs}
          </p>
        )}
        {navbarData && (
          <p
            onClick={() => handleNavLinkClick("team")}
            className="text-black uppercase text-base cursor-pointer"
          >
            {navbarData.teams}
          </p>
        )}
        {navbarData && (
          <Link
            to={`/${pageData && pageData.reviews ? pageData.reviews : "reviews"}`}
            className="text-black uppercase text-base cursor-pointer"
          >
            {navbarData.reviews}
          </Link>
        )}
        {navbarData && (
          <p
            onClick={() => handleNavLinkClick("contact-us")}
            className="text-black uppercase text-base bg-yellow-500 p-3 rounded-xl cursor-pointer"
          >
            {navbarData.contact} US
          </p>
        )}
      </div>
    </nav>
  );
}






// import React, { useState, useEffect } from "react";
// import { Link, useNavigate, useLocation } from "react-router-dom";
// import imageUrlBuilder from "@sanity/image-url";
// import client from "../../sanityCli";

// const builder = imageUrlBuilder(client);

// function urlFor(source) {
//   console.log("source", source);
//   return builder.image(source);
// }


// export default function Navbar() {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [navbarData, setNavbarData] = useState(null);
//   const [pageData, setPageData] = useState(null);
//   const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
//   const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

//   useEffect(() => {
//     let passedValue = localStorage.getItem("myData");
//     if (passedValue !== "true") {
//       window.location.reload();
//       localStorage.setItem("myData", "true");
//     }
//     const fetchData = async () => {
//       try {
//         const result = await client.fetch('*[_type == "navbarContent"][0]');
//         setNavbarData(result);
//         console.log("navdata", result);
//       } catch (error) {
//         console.error("Error fetching data from Sanity:", error);
//       }
//     };

//     fetchData();

//     const fetchPageData = async () => {
//       try {
//         const result = await client.fetch('*[_type == "pageContent"][0]');
//         setPageData(result);
//         console.log("pageNavData", result);
//       } catch (error) {
//         console.error("Error fetching data from Sanity:", error);
//       }
//     };
//     fetchPageData();

//     const handleDocumentClick = (event) => {
//       if (
//         isMobileMenuOpen &&
//         !event.target.closest(".mobile-menu") &&
//         !event.target.closest(".hamburger-button")
//       ) {
//         setIsMobileMenuOpen(false);
//       }
//     };

//     if (isMobileMenuOpen) {
//       document.body.addEventListener("click", handleDocumentClick);
//     }

//     return () => {
//       document.body.removeEventListener("click", handleDocumentClick);
//     };
//   }, [isMobileMenuOpen]);

//   const closeMobileMenu = () => {
//     setIsMobileMenuOpen(false);
//   };

//   const handleNavLinkClick = (sectionId) => {
//     console.log("section", sectionId);
//     if (location.pathname !== "/") {
//       // Navigate to the home page first
//       navigate("/");
//       // After navigating to home, scroll to the specified section
//       setTimeout(() => {
//         const element = document.getElementById(sectionId);
//         if (element) {
//           element.scrollIntoView({ behavior: "smooth" });
//         }
//       }, 500); // Adjust the delay time as needed
//     } else {
//       // Scroll to the specified section with smooth behavior
//       const element = document.getElementById(sectionId);
//       if (element) {
//         element.scrollIntoView({ behavior: "smooth" });
//       }
//     }
//     // Close mobile menu if it's open
//     closeMobileMenu();
//   };

//   return (
//     <nav className="navbar flex items-center justify-between py-5 px-6 md:px-10 lg:px-20 2xl:px-28  bg-white shadow-md">
//       <div className="navbar-logo">
//         {navbarData && <img src={urlFor(navbarData.logo)} alt="logo-image" />}
//       </div>
//       <div className="md:hidden hamburger-button">
//         <button onClick={toggleMobileMenu}>
//           {navbarData && <img src={urlFor(navbarData.hamburgerBtn)} />}
//         </button>
//       </div>
//       {isMobileMenuOpen && (
//         <div className="mobile-menu md:hidden absolute top-0 right-0 z-10 bg-white p-4 w-full shadow-lg md">
//           <div className="flex flex-col gap-4 pt-10 pb-2 px-4 text-3xl">
//             <button
//               className="absolute top-2 p-4 right-2 bg-black:500 shadow-lg"
//               onClick={closeMobileMenu}
//             >
//               {navbarData && <img src={urlFor(navbarData.crossBtn)} />}
//             </button>

//             {navbarData && (
//               <p
//                 onClick={() => handleNavLinkClick("whatWeDo")}
//                 className="text-black uppercase text-sm"
//               >
//                 {navbarData.whatWeDo}
//               </p>
//             )}
//             {navbarData && (
//               <p
//                 className="text-black uppercase text-sm"
//                 onClick={handleNavLinkClick("topGames")}
//               >
//                 {navbarData.topGames}
//               </p>
//             )}
//             {navbarData && pageData && (
//               <Link
//                 to={`/${pageData.blog ? pageData.blog : "blog"}`}
//                 className="text-black text-sm uppercase"
//               >
//                 {navbarData.blog}
//               </Link>
//             )}
//            {navbarData && (
//           <p
//             onClick={() => handleNavLinkClick("about")}
//             className="text-black uppercase text-sm"
//           >
//             {navbarData.aboutUs}
//           </p>
//         )}
//             {navbarData && (
//               <p
//                 onClick={() => handleNavLinkClick("team")}
//                 className="text-black uppercase text-sm"
//               >
//                 {navbarData.teams}
//               </p>
//             )}
//             {navbarData && (
//               <Link to={"/"} className="text-black text-sm uppercase">
//                 {navbarData.reviews}
//               </Link>
//             )}
//             {navbarData && (
//               <p
//                 onClick={() => handleNavLinkClick("contact-us")}
//                 className="text-black uppercase text-sm bg-yellow-500 p-3 rounded-xl cursor-pointer"
//               >
//                 {navbarData.contact}
//               </p>
//             )}
//           </div>
//         </div>
//       )}
//       <div className="hidden md:flex items-center space-x-4 xl:space-x-8">
//         {navbarData && (
//           <p
//             onClick={() => handleNavLinkClick("whatWeDo")}
//             className="text-black uppercase text-base cursor-pointer"
//           >
//             {navbarData.whatWeDo}
//           </p>
//         )}
//         {navbarData && (
//           <p
//             onClick={() => handleNavLinkClick("topGames")}
//             className="text-black uppercase text-base cursor-pointer"
//           >
//             {navbarData.topGames}
//           </p>
//         )}
//         {navbarData && pageData && (
//           <Link
//             to={`/${pageData.blog ? pageData.blog : "blog"}`}
//             className="text-black uppercase text-base cursor-pointer"
//           >
//             {navbarData.blog}
//           </Link>
//         )}
//         {navbarData && (
//           <p
//             onClick={() => handleNavLinkClick("about")}
//             className="text-black uppercase text-base cursor-pointer"
//           >
//             {navbarData.aboutUs}
//           </p>
//         )}
//         {navbarData && (
//           <p
//             onClick={() => handleNavLinkClick("team")}
//             className="text-black uppercase text-base cursor-pointer"
//           >
//             {navbarData.teams}
//           </p>
//         )}
//         {navbarData && pageData && (
//           <Link
//             to={`/${pageData.reviews ? pageData.reviews : "reviews"}`}
//             className="text-black uppercase text-base cursor-pointer"
//           >
//             {navbarData.reviews}
//           </Link>
//         )}
//         {navbarData && (
//           <p
//             onClick={() => handleNavLinkClick("contact-us")}
//             className="text-black uppercase text-base bg-yellow-500 p-3 rounded-xl cursor-pointer"
//           >
//             {navbarData.contact} US
//           </p>
//         )}
//       </div>
//     </nav>
//   );
// }
